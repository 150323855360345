<template>
  <div>
    <!-- 轮播图 -->
    <div class="banner" @wheel="handleMouseWheel">
      <!-- <el-carousel height="830px" style="width: auto" ref="cardShow"> -->
      <el-carousel
        :height="height + 'px'"
        style="width: auto"
        ref="cardShow"
        arrow="always"
      >
        <el-carousel-item v-for="(item, index) in carousel" :key="index">
          <div
            class="pic"
            :style="'background-image: url(' + require('@/' + item.url) + ')'"
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 主体部分 -->
    <!-- <div class="mainOne">
      <div class="wrap"></div>
    </div> -->
  </div>
</template>

<script>
import Home_content from '../../../public/Home_content';
import { get } from '../../utils/request';

export default {
  data() {
    return {
      height: 830,
      carousel: Home_content.bannersimg,
    };
  },
  created() {
    this.height = document.body.clientHeight - 100;
  },
  methods: {
    handleMouseWheel(e) {
      return;
      if (e.wheelDelta) {
        // 判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) {
          this.$emit('handleNextPanel', false);
        }
        if (e.wheelDelta < 0) {
          // 当滑轮向下滚动时
          this.$emit('handleNextPanel', true);
        }
      } else if (e.detail) {
        // Firefox滑轮事件
        if (e.detail > 0) {
          // 当滑轮向下滚动时
          this.$emit('handleNextPanel', true);
        }
        if (e.detail < 0) {
          // 当滑轮向上滚动时
          this.$emit('handleNextPanel', false);
        }
      }
    },
  },
};
</script>
<style lang="less">
.banner {
  .el-carousel__indicators--horizontal {
    .el-carousel__button {
      width: 40px;
      height: 4px;
    }
  }
  .el-icon-arrow-right,
  .el-icon-arrow-left {
    font-size: 30px;
  }
}
</style>
<style lang="less" scoped>
// 轮播图
.banner {
  // max-width: 2560px;
  margin: 0 auto;
  text-align: center;
  // position: relative;
  height: calc(100vh - 100px);
  overflow: hidden;
  .el-carousel__item {
    // width: 100%;
    background: #fff;
    .pic {
      background-size: cover;
      color: #fff;
      background-position: center center;
      height: 100%;
      position: relative;
      overflow: hidden;
      // background-color: #94070a;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  /deep/.el-carousel__indicators {
    z-index: 999;
  }

  .arrow {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 95%;
    z-index: 99;

    .leftArrow {
      float: right;
      display: inline-block;
      width: 42px;
      height: 42px;
      margin-right: 15px;
      background: url('../../assets/yxtx/bg-left.png');
    }

    .leftArrow:hover {
      background: url('../../assets/yxtx/left.png');
      cursor: pointer;
    }

    .rightArrow {
      float: right;
      display: inline-block;
      width: 42px;
      height: 42px;
      background: url('../../assets/yxtx/bg-right.png');
    }

    .rightArrow:hover {
      background: url('../../assets/yxtx/right.png');
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1200px) {
    .el-carousel__item {
      width: 1200px;
      background-color: #ccc;
    }

    .arrow {
      width: 1140px;
      position: absolute;
      bottom: 6px;
      left: 0;
    }

    .leftArrow {
      float: right;
    }

    .rightArrow {
      float: right;
    }
  }
}
</style>
