<template>
  <div class="main-tab">
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/1.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/2.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/3.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/4.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/5.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/6.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/7.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/8.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/9.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/10.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/11.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/12.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/13.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/14.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/15.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/16.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/17.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/18.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/19.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/20.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/21.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/22.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/23.jpg')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeight/24.jpg')" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.main-tab {
  padding: 0 20px;
  width: 1100px;
  margin: 0 auto;
  padding-top: 60px;
  .introduct {
    padding: 10px 10px 0;
    line-height: 28px;
  }
  .tabimg {
    width: calc(100% - 20px);
    text-align: center;
    padding: 0 10px;
    img {
      max-width: 100%;
    }
  }
  .titleintroduce {
    padding: 10px 10px 0;
    .sufixx {
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #000;
      margin-right: 10px;
    }
  }
}
</style>
