<template>
  <div class="tab-four">
    <div class="left-content">
      <div class="main-content">
        <div class="inner-title">
          <div class="title">
            <div>物联网监测监控</div>
          </div>
          <div class="subtitle"></div>
        </div>
        <p class="introduct">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司具有智能电表、智能水表、水文仪器、水利自动化仪器等物联网监测监控集成实施能力，自主研发了水雨情监测、大坝安全监测、污染源在线监测、排水管网监测、水电暖智能控制及计收、防汛预警预报、闸门与泵站调度监控、水环境监测与水资源实时监控等物联网监测监控系统。
        </p>
        <div class="bottom-title"></div>
      </div>
    </div>
    <div class="pic">
      <img :src="require('../../assets/yskimg/business/wlwjk.png')" />
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/business.json';
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-four {
  padding-top: 60px;
  display: flex;
  width: 90%;
  margin: 0 auto;

  .pic {
    width: 748px;
    height: 451px;
    // background: #ffffff;
    // // box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // transition: all 0.6s ease-out 0s;
    // background: url('../../assets/yskimg/business/wlwjk.png') no-repeat center;

    // background-size: 100% 100%;
  }
  @media screen and (max-width: 1440px) {
    .pic {
      width: 548px;
      height: 451px;
    }
  }
  .left-content {
    flex: 1;
    position: relative;

    .main-content {
      width: 100%;
      background: #ffffff;
      height: 454px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
    }
  }

  .inner-title {
    // padding-left: 20px;
    position: relative;

    .title {
      font-size: 26px;
      color: #208ee1;
      line-height: 48px;
    }

    .subtitle {
      position: absolute;
      height: 0px;
      width: 100px;
      border: 1px solid #208ee1;
    }
  }

  .introduct {
    font-size: 18px;
    color: #000000;
    line-height: 48px;
    padding-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;

    .single {
      width: 21px;
      height: 18px;
      font-size: 60px;
      font-weight: 400;
      color: #208ee1;
      line-height: 30px;
    }
  }
  .bottom-title {
    position: absolute;
    bottom: 0;
    width: 48px;
    height: 18px;
    background: #208ee1;
  }
}
</style>
