<template>
  <div class="tab-one">
    <div class="solution-panel">
      <SolutionTitleLayout
        title="背景介绍"
        subtitle="Background"
      ></SolutionTitleLayout>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ introduce }}
      </p>
      <SolutionTitleLayout
        title="建设目标"
        subtitle="Construction goals"
      ></SolutionTitleLayout>
      <img
        class="solutionimg"
        :src="require('@/assets/yskimg/solution/goals.png')"
      />
      <SolutionTitleLayout
        title="优势特点"
        subtitle="Superior Characteristic"
      ></SolutionTitleLayout>
      <img
        class="solutionimg"
        :src="require('@/assets/yskimg/solution/Characteristic.png')"
      />

      <!-- <SolutionTitleLayout
        title="应用架构"
        subtitle="Application results"
      ></SolutionTitleLayout> -->
      <!-- <img class="solutionimg" :src="require('@/assets/yskimg/solution/Application.png')" /> -->
      <SolutionTitleLayout
        title="成功案例"
        subtitle="Successful cases"
      ></SolutionTitleLayout>
      <div class="swiper-father">
        <div class="swiper-container swiper-container1">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in datalist"
              :key="index"
            >
              <div
                class="case-pic"
                :style="
                  'background-image: url(' + require('@/' + item.picurl) + ')'
                "
              ></div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <!-- 我们把swiper 的箭头放到swiper 外面 -->
        <!-- <div class="swiper-button-prev swiper-button-prev1"></div>
        <div class="swiper-button-next swiper-button-next1"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {
      introduce:
        '党的十八大报告中，把“信息化”上升到了国家战略、民族战略的高度。水利部从治水思路出发提出“水利信息化带动水利现代化发展”的需要。新一轮智慧城市建设行动计划。水务局自身发展需要，整合水务和海洋两大资源，构建智慧监测预警,决策支持,组织指挥体系。社会民生要求得到更快更全更细更周到的服务,转变以往水务部门多管理少服务的态势。 数据加工、地图编制、测绘监理、地理信息数据建库、GIS数据库管理系统、专业GIS系统开发等。',
      activeMainLiID: 1,
      datalist: Resultjson.data.datalist,
    };
  },
  created() {},
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container1', {
        direction: 'horizontal',
        slidesPerView: 2,
        spaceBetween: 30, //轮播图之间的间距
        loop: true,
        autoplay: true, //可选选项，自动滑动
        // navigation: {
        //   nextEl: '.swiper-button-next1',
        //   prevEl: '.swiper-button-prev1',
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tab-one {
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  p.introduce {
    line-height: 48px;
    margin: 60px 0;
  }
  .solutionimg {
    margin: 60px 0;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
    // .swiper-button-prev,
    // .swiper-button-next {
    //   width: 30px;
    //   height: 60px;
    //   background: #c7c7c7;
    //   color: #fff;
    // }
  }
}
</style>
