<template>
  <PanelLayout :bgcolor="bgcolor">
    <div
      :class="paneloneShow ? 'main_contentbg showdiv' : 'main_contentbg'"
      id="panelone"
    >
      <div class="main">
        <div class="main_top">
          <TitleComponentLayout
            :title="home_content1.titleEng1"
            :subtitle="home_content1.title"
            :describe="home_content1.titlemsg"
          ></TitleComponentLayout>
          <div class="main_top_right" @click="handleMore(1)">
            <i style="cursor: pointer">READ ALL +</i>
          </div>
        </div>
        <div class="main_bottom">
          <div class="main_ul">
            <div
              :class="activeMainLiID === item.id ? 'main_li active' : 'main_li'"
              v-for="(item, index) in home_content1.main"
              :key="index"
              @mouseenter="handlemouseenter(item)"
              @mouseleave="handlemouseleave"
              @click="handleMore(item.id)"
              :style="'transition-delay:0.' + index + 's'"
            >
              <div class="icon">
                <img
                  v-if="activeMainLiID === item.id"
                  :src="require('@/' + item.activeiconurl)"
                  alt=""
                />
                <img v-else :src="require('@/' + item.iconurl)" alt="" />
              </div>
              <div class="title fz25">{{ item.title }}</div>
              <div class="points"></div>
              <!-- <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  <div class="tip-message" style="width: 200px">
                    {{ item.content }}
                  </div>
                </div>
                <div class="message">{{ item.content }}</div>
              </el-tooltip> -->
              <div class="message">{{ item.content }}</div>
              <!-- <div
                :class="
                  activeMainLiID === item.id
                    ? 'detail_icon show'
                    : 'detail_icon'
                "
              >
                <img src="@/assets/yskimg/home/arrow.png" alt="" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </PanelLayout>
</template>

<script>
import Home_content from '../../../public/Home_content';
import PanelLayout from '@/components/layout/PanelLayout';
import TitleComponentLayout from '@/components/layout/TitleComponentLayout';
import { get } from '../../utils/request';
export default {
  props: ['paneloneShow'],
  components: {
    PanelLayout,
    TitleComponentLayout,
  },
  data() {
    return {
      bgcolor: '#f5f5f5',
      home_content1: Home_content.home_content1,
      activeMainLiID: '',
    };
  },

  created() {},

  methods: {
    handlemouseenter(item) {
      this.activeMainLiID = item.id;
    },
    handlemouseleave() {
      this.activeMainLiID = '';
    },
    handleMore(id) {
      this.$router.push({
        path: '/business',
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main_contentbg {
  background: #f5f5f5;
  // height: 900px;
  // max-width: 2560px;
  margin: 0 auto;
  color: #000;

  .main {
    overflow: hidden;
    // width: 1600px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;

    .main_top {
      display: flex;
      justify-content: space-between;

      .main_top_right {
        width: 90px;
        margin-top: 10px;
      }
    }

    .main_bottom {
      .main_ul {
        width: calc(100% - 90px);
        margin: 0 auto;
        // height: 460px;
        height: auto;
        margin-top: 60px;
        display: flex;

        .main_li {
          width: 360px;
          height: 460px;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 60px 0 0 45px;
          margin-right: 20px;
          cursor: pointer;
          opacity: 0;
          transition: all 0.3s ease-out 0s;
          transform: translateY(50px);

          &.active {
            background: #208fe1;
            color: #fff;

            .points {
              background-color: #fff;
            }
          }

          .icon {
            width: 75px;
            height: 65px;
          }

          .title {
            margin-top: 40px;
            font-weight: 500;
          }

          .points {
            width: 32px;
            height: 4px;
            background-color: #000;
            margin: 60px 0 45px 0;
            transition: all 0.3s ease-out 0s;
          }

          .message {
            width: 255px;
            height: 132px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
          }

          .detail_icon {
            display: none;
            width: 46px;
            height: 11px;
            margin-top: 40px;

            &.show {
              display: inline-block;
            }
          }
        }
      }

      @media screen and (max-width: 1600px) {
        .main_ul {
          flex-wrap: wrap;
          height: auto;
          justify-content: space-around;

          .main_li {
            width: calc(40% - 20px);
          }
        }
      }
    }
  }

  &.showdiv {
    .main {
      .main_bottom {
        .main_ul {
          .main_li {
            transform: none;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
