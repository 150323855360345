<template>
  <div class="tab-one">
    <div class="solution-panel">
      <SolutionTitleLayout
        title="背景介绍"
        subtitle="Background"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2017年习近平总书记视察北京工作时指出:"要坚决维护总体规划严肃性和权威性，健全规划实时监测、定期评估、动态维护制度，建立城市体检(评估机制，加大执法力度，提高违法成本，健全问责机制。"此后各地开展了创新性体检评估的探索，通过多轮试点不断总结经验，2021年自然资源部制定并发布《国土空间规划城市体检评估规程》，明确了城市体检评估的工作路径及方法，至此城市体检评估进入了有据可依的新阶段。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabthree/one.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="建设目标"
        subtitle="Construction Goals"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结合国土空间规划城市体检评估要求，充分运用新兴技术手段，以指标模型为驱动，通过数据实时获取、意见实时收集、指标实时计算、终端实时展示、预警实时推送和治理实时跟踪，构建全要素覆盖、多维度分析、全周期监管、多主体共治的国土空间规划城市体检评估应用体系;通过多维度、多层次对战略定位、底线管控、规模结构、空间布局、支撑系统、实施保障等六大方面实施评估，保障规划、建设、运营高效运转，提升城市人居环境治理的科学技术支撑能力，助力建设安全、创新、协调、绿色、开放、共享并独具魅力的美好城市。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabthree/two.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="体检方法"
        subtitle="Physical Examination Methods"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;城市体检评估平台按照住建部对城市体检的要求，结合城市自身特点，构建特色指标，建立问题导向、目标导向、结果导向相结合的城市体检指标体系。运用统计、空间分析、大数据分析和社会满意度调查等方法，采集城市相关信息，综合评价城市发展建设状况、发现“城市病”，有针对性地制定对策措施。
      </p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过专业城市体检技术信息平台的建立，实现城市体检的数据采集、校核更新、综合查询、统计分析、模型分析、评估预警、专题图与体检报告的生成等功能，支撑“一年一体检，五年一评估”城市体检机制，形成历年指标数据的统一管理、实时回溯与对比分析。
      </p>
      <p class="bottomdistance"></p>
      <!-- Construction Of Three Warehouses -->
      <SolutionTitleLayout title="三库建设" subtitle=""></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>体检评估专题数据库
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过多部门数据采集、多系统共享提取，全方位收集体检评估所需基础数据，通过整合、加工、抽取、转换、分析、融合等数据处理手段，建立面向城市体检评估的专题数据库，为专题服务系统提供数据支撑。
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>体检评估指标库
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;根据规程要求，在必选指标的基础上，结合本地发展情况选择推荐指标，自行增设反映地方特色的自选指标，同时纳入所有国土空间总体规划指标，并充分考虑指标的覆盖度、指标层次、指标要素关系、人本性等，形成多维度全方位的指标体系。
        </div>
      </div>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabthree/three.png')" />
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>体检评估模型库
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为实现城市体检评估的智能分析，应配置指标及指标体系的计算模型和状态模型用于智能化评估。构建专业化评估模型，并通过计算机语言，集合地理空间分析和数理统计分析，编写应用模型的算子，通过对各类算子进行编排、管理，建成适用不同场景的体检评估模型，实现体检评估从“被动统计”向“主动挖掘”的转变。
        </div>
      </div>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabthree/four.png')" />
      </div>
      <p class="bottomdistance"></p>
      <!-- Physical Examination Evaluation Application System -->
      <SolutionTitleLayout
        title="体检评估应用体系"
        subtitle=""
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以信息化为手段，以政策机制为保障，建立横向到边、纵向到底、全周期、全覆盖的城市体检评估应用体系，逐步实现“指标收集-智能评估-决策支持”管理全流程、“自然资源局-各委办局-社会公众”环节共参与、“网页端-移动端-大屏端”服务多终端。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabthree/five.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="国土空间规划城市体检评估信息系统"
        subtitle=""
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;依托多源大数据自动采集、处理与融合技术、指标模型构建及动态可视化等技术，构建覆盖体检评估“指标收集-数据校核-智能评估-可视化展示-报告导出”全过程的应用系统，全面支撑规划编制调整、规划成效动态评估以及城市问题及时预警。
      </p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;关键技术:
      </p>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span
          >多维度、多单元耦合的体检与比对：时空、时序对比、空间单元横向比较；市、区至街道、社区多尺度关联评估。
        </div>
        <div class="title introduce fz18">
          <span class="sufixx"></span
          >动态感知与持续更新方法：结合时空大数据，持续感知城市建设与治理等量化指标。
        </div>
        <div class="title introduce fz18">
          <span class="sufixx"></span
          >专题图定制与报告生成：采用嵌入技术，辅助体检报告生成。
        </div>
        <div class="title introduce fz18">
          <span class="sufixx"></span
          >前沿技术应用：高效的数据模型、多维度的空间算法，专业的评价模型。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabthree/six.png')" />
        </div>
      </div>
      <p class="bottomdistance"></p>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {
      data: Resultjson.data.TabData[2],
    };
  },
  created() {},
  mounted() {
    if (this.data.successcase) {
      this.getBanner();
    }
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container3', {
        direction: 'horizontal',
        slidesPerView: 2,
        // spaceBetween: 30, //轮播图之间的间距
        loop: false,
        autoplay: true, //可选选项，自动滑动
        observer: true,
        observeParents: true,
        // navigation: {
        //   nextEl: '.swiper-button-next1',
        //   prevEl: '.swiper-button-prev1',
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tab-one {
  .tabimg {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  .topdistance {
    margin-top: 60px;
  }
  .bottomdistance {
    margin-bottom: 60px;
  }
  .introduce {
    line-height: 48px;
  }
  .solutionimg {
    margin: 60px 0;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
    // .swiper-button-prev,
    // .swiper-button-next {
    //   width: 30px;
    //   height: 60px;
    //   background: #c7c7c7;
    //   color: #fff;
    // }
  }
  .plus {
    .title {
      .sufixx {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        margin-right: 10px;
      }
    }
  }
}
</style>
