<template>
  <div class="mainTwo">
    <div class="wrap">
      <div class="Left">
        <h1 class="Title">
          <span class="tit">公告<em>通知</em></span>
          <span class="line line_Ls">
            <b class="line_l"></b>
            <b class="line_s"></b>
          </span>
          <a class="more" href="#" @click="toPage('/notice')">更多 <font size="+0">&gt;&gt;</font></a>
        </h1>
        <ul class="listl_thr">
          <li v-for="item in notesArticle" :key="item.id" @click.prevent="overallClick(item)">
            <a href="#" :title="item.title">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="Middle">
        <h1 class="Title">
          <span class="tit">焦点<em>新闻</em></span>
          <span class="line line_Ms">
            <b class="line_l"></b>
            <b class="line_s"></b>
          </span>
          <a class="more" href="#" @click="toPage('/notice/news')">更多 <font size="+0">&gt;&gt;</font></a>
        </h1>
        <ul class="listm_thr">
          <li v-for="item in newsArticle" :key="item.id"  @click.prevent="overallClick(item)">
            <a href="#" class="img fl">
              <img :src="item.cover" />
            </a>
            <div class="wen fl">
              <a href="#" :title="item.title">{{ item.title }}</a>
              <p>
                {{ item.content | ellipsis(50) }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="Right">
        <h1 class="Title2"><span>师资队伍</span><a href="#" @click="toPage('/teacher')">更多</a></h1>
        <superslide
          :options="superSlideOption"
          v-if="superShow"
          class="picScroll-top"
        >
          <div class="bd">
            <div class="tempWrap">
              <ul class="picList">
                <li
                  class="clone"
                  style="height: 95px"
                  v-for="item in teacherArticle"
                  :key="item.id"
                   @click.prevent="overallClick(item)"
                >
                  <a href="#"  class="pic fl"><img :src="item.cover"/></a>
                  <div class="wen fl">
                    <h2>
                      <a href="#">{{ item.title }}</a>
                    </h2>
                    <p>{{ item.content | ellipsis(22) }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </superslide>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "../../utils/request";
export default {
  data() {
    return {
      notesArticle: [],
      newsArticle: [],
      teacherArticle: [],
      superShow: false,
      superSlideOption: {
        titCell: ".hd ul",
        mainCell: ".bd ul",
        autoPlay: true,
        effect: "topLoop",
        vis: 2,
        trigger: "click",
      },
    };
  },
  created() {
    this.getNotesData();
    this.getNewsData();
    this.getTeacherData();
  },
  methods: {
    // 公告通知
    getNotesData() {
      get("/index/article/pageQuery", {
        page: 1,
        pageSize: 9,
        categoryId: 27,
      }).then((res) => {
        if (res.status == 200) {
          this.notesArticle = res.data.list;
        }
      });
    },
    // 查询焦点新闻栏目所有的文章前2个
    getNewsData() {
      get("/index/article/pageQuery", {
        page: 1,
        pageSize: 2,
        categoryId: 28,
      }).then((res) => {
        if (res.status == 200) {
          this.newsArticle = res.data.list;
        }
      });
    },
    // 查询教师团队栏目前5个
    getTeacherData() {
      get("/index/article/pageQuery", {
        page: 1,
        pageSize: 5,
        categoryId: 15,
      }).then((res) => {
        if (res.status == 200) {
          this.teacherArticle = res.data.list;
          this.superShow = false;
          this.$nextTick(() => {
            this.superShow = true;
          });
        }
      });
    },
    // 点击当前行
    overallClick(row) {
      this.$router.push({ 
        path: '/notice/details',
        query:{
          id:row.id,
        } 
      })
    },
    toPage(path){
      this.$router.push({path})
    }
  },
};
</script>
<style lang='less' scoped>
.fl {
  float: left;
}
.mainTwo {
  width: 100%;
  .wrap > div {
    width: 290px;
    padding: 0 5px;
    float: left;
  }
  .wrap {
    width: 1200px;
    margin: 0 auto;
    height: 290px;
    margin-top: 30px;
    
    .Title2 {
      width: 100%;
      background: url(../../assets/yxtx/ico3.png) no-repeat;
      height: 36px;
      line-height: 36px;
      margin-bottom: 10px;
      font-size: 18px;
      color: #ffffff;
      span {
        display: block;
        float: left;
        padding: 0 12px;
      }
      a {
        float: right;
        color: #464646;
        font-size: 18px;
        display: block;
        padding: 0 12px;
      }
    }
    .Left {
      height: 290px;
      .listl_thr {
        width: 100%;
        height: auto;
        li a {
          width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          color: #323232;
          line-height: 28px;
          padding-left: 12px;
          display: block;
          background: url(../../assets/yxtx/ico5.png) no-repeat left;
        }
        li a:hover {
          color: #680034;
          text-decoration: underline;
        }
      }
    }
    .Middle {
      width: 590px;
      height: 290px;
      .listm_thr {
        width: 100%;
        height: auto;
        li {
          width: 100%;
          height: 95px;
          border-bottom: 1px dotted #ccc;
          padding-bottom: 20px;
          margin-bottom: 20px;
          .img {
            display: block;
            width: 153px;
            margin-right: 20px;
            img {
              width: 153px;
              height: 95px;
            }
          }
          .wen {
            width: 417px;
            font-size: 14px;
            color: #787878;
            a {
              color: #323232;
              font-size: 16px;
              padding-top: 6px;
              font-weight: bold;
              display: block;
              width: 320px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            p {
              text-indent: 2em;
              line-height: 22px;
              padding-top: 8px;
            }
          }
        }
      }
    }
    .Right {
      .picScroll-top {
        width: 100%;
        height: 252px;
        overflow: hidden;
        .bd {
          width: 100%;
          .picList {
            width: 100%;
            position: relative;
            li {
              width: 100%;
              padding-bottom: 20px;
              margin-bottom: 20px;
              border-bottom: 1px dotted #ccc;
              .pic {
                display: block;
                width: 93px;
                height: 95px;
                img {
                  width: 100%;
                  height: 100px;
                }
              }
              .wen {
                width: 183px;
                padding-left: 14px;
                h2 {
                  font-weight: bold;
                  padding: 8px 0;
                  font-size: 16px;
                  a {
                    color: #323232;
                  }
                }
                p {
                  color: #787878;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>