<template>
  <PanelLayout :bgcolor="bgcolor">
    <div
      :class="panelthreeShow ? 'main_contentbg showdiv' : 'main_contentbg'"
      id="panelthree"
    >
      <div class="main">
        <div class="main_top">
          <TitleComponentLayout
            :title="home_content1.titleEng1"
            :subtitle="home_content1.title"
            :describe="home_content1.titlemsg"
          ></TitleComponentLayout>
          <div class="main_top_right" @click="handleMore">
            <i style="cursor: pointer">READ ALL +</i>
          </div>
        </div>
        <div class="main_bottom">
          <div class="main_ul">
            <div class="main1">
              <el-carousel height="500px" style="width: auto" ref="cardShow">
                <el-carousel-item
                  v-for="(item, index) in carousel"
                  :key="index"
                >
                  <router-link
                    target="_blank"
                    :to="{
                      path: '/newsdetail',
                      query: { id: item.uuid, title: item.title },
                    }"
                  >
                    <!-- <img :src="item.image" alt="" /> -->
                    <div
                      class="pic newspic"
                      :style="'background-image:url(' + item.image + ')'"
                    >
                      <div class="title">
                        <div class="titleinner">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </router-link>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="rg">
              <div class="main2 flexcol">
                <div
                  class="new-item1"
                  v-for="(item, index) in leftnews"
                  :key="index"
                >
                  <div class="new-item1-inner">
                    <div class="new-title">{{ item.title }}</div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.content }}
                    </p>
                    <div class="news-bottom">
                      <div class="new-time">
                        <div class="new-lf">{{ item.daytime }}</div>
                        <div class="new-rg">
                          {{ item.yeartime }}.{{ item.mothtime }}
                        </div>
                      </div>
                      <router-link
                        target="_blank"
                        :to="{
                          path: '/newsdetail',
                          query: { id: item.uuid, title: item.title },
                        }"
                        style="height: 100%"
                      >
                        <div class="detail-icon">
                          <span class="icon">
                            <i class="el-icon-arrow-right"></i>
                          </span>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main3 flexcol">
                <div
                  class="new-item2"
                  v-for="(item, index) in rightnews"
                  :key="index"
                >
                  <div class="new-item2-inner">
                    <div class="lf">
                      <div class="new-lf">{{ item.daytime }}</div>
                      <div class="new-rg">
                        {{ item.yeartime }}.{{ item.mothtime }}
                      </div>
                    </div>
                    <div class="rg">
                      <div class="new-title">{{ item.title }}</div>
                      <router-link
                        target="_blank"
                        :to="{
                          path: '/newsdetail',
                          query: { id: item.uuid, title: item.title },
                        }"
                      >
                        <span class="btn-arrow"></span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PanelLayout>
</template>

<script>
import Home_content from '../../../public/Home_content';
import PanelLayout from '@/components/layout/PanelLayout';
import TitleComponentLayout from '@/components/layout/TitleComponentLayout';
import Resultjson from '../../../public/json/news.json';
import { get } from '@/utils/request.js';
import global from '../../../public/json/global.json';
export default {
  props: ['panelthreeShow'],
  components: {
    PanelLayout,
    TitleComponentLayout,
  },
  data() {
    return {
      bgcolor: '#f5f5f5',
      home_content1: Home_content.home_content3,
      leftnews: [],
      rightnews: [],
      carousel: [],
      // Newsdata: Resultjson.data.datalist,
      Newsdata: [],
      pageNum: 1,
      pageSize: 10,
    };
  },

  created() {
    this.getInitTableData(1);
  },

  methods: {
    handleMore() {
      this.$router.push({
        path: '/news',
      });
    },
    handleMoreNews(id) {
      this.$router.push({
        path: '/newsdetail',
        query: { id: JSON.stringify(id) },
      });
    },
    getInitTableData(num) {
      this.pageNum = num;
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      get('/news/list', params).then((res) => {
        this.Newsdata = res.data.rows;
        if (this.Newsdata.length > 0) {
          this.Newsdata.map((item) => {
            let tempPath = item.coverImg.relativePath;
            tempPath = tempPath.replace(/\\/g, '/');
            item.image = tempPath ? global.uploadUrl + tempPath : '';
          });
          this.resetDatas();
        }
      });
    },
    resetDatas() {
      this.carousel = this.Newsdata.slice(0, 5);
      this.leftnews = this.Newsdata.slice(0, 2);
      this.leftnews.map((item) => {
        if (item.publishTime) {
          let newtime = item.publishTime.split(' ');
          let time = newtime[0].split('-');
          item.daytime = time[2];
          item.mothtime = time[1];
          item.yeartime = time[0];
        }
        if (item.content) {
          const re1 = new RegExp('<.+?>', 'g'); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
          const msg = item.content.replace(re1, ''); //执行替换成空字符
          item.content = msg;
        }
      });
      this.rightnews = this.Newsdata.slice(2, 5);
      this.rightnews.map((item) => {
        if (item.publishTime) {
          let newtime = item.publishTime.split(' ');
          let time = newtime[0].split('-');
          item.daytime = time[2];
          item.mothtime = time[1];
          item.yeartime = time[0];
        }
      });
    },
  },
};
</script>
<style lang="less">
#panelthree {
  .el-carousel__indicators--horizontal {
    bottom: 5px;
    left: calc(100% - 100px);
    transform: none;
    .el-carousel__button {
      width: 6px;
      height: 6px;
      border-radius: 3px;
    }
  }
}
</style>
<style lang="less" scoped>
.main_contentbg {
  margin: 0 auto;
  color: #000;

  .main {
    overflow: hidden;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;

    .main_top {
      display: flex;
      justify-content: space-between;

      .title_topmain {
        display: flex;
        line-height: 40px;

        .title_left {
          font-size: 40px;
          font-weight: 600;
        }

        .title_center {
          width: 80px;
          height: 2px;
          background: #222;
          margin: 19px 10px;
          line-height: 40px;
        }

        .title_right {
          font-size: 26px;
        }
      }

      .main_top_right {
        margin-top: 10px;
      }
    }

    .main_bottom {
      .main_ul {
        margin: 0 auto;
        height: 500px;
        margin-top: 60px;
        display: flex;
        justify-content: space-between;

        .main1 {
          width: 640px;
          background: #fff;
          transform: translateX(-50px);
          transition: all 1s ease-out 0s;
          opacity: 0;

          .pic {
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 0.6s ease-out 0s;
            position: relative;
            // margin: 0 30px;
            box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 7%);
          }
        }

        .rg {
          flex: 1;
          display: flex;
          justify-content: space-around;
          // width: 60%;
          transform: translateX(50px);
          transition: all 1s ease-out 0s;
          opacity: 0;
        }

        .main2 {
          margin-left: 20px;
          width: 50%;

          .new-item1:first-child {
            margin-bottom: 10px;
          }
          .news-bottom {
            display: flex;
            flex: 1;
            border-top: 1px solid #f2f2f2;
            .new-time {
              flex: 1;
            }
            .detail-icon {
              width: 50px;
              height: 100%;
              color: #999999;
              display: flex;
              flex-direction: column;
              justify-content: center;
              cursor: pointer;
              .icon {
                display: inline-block;
                height: 32px;
                width: 32px;
                border: 1px solid #999999;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 23px;
              }
            }
          }
          div.new-item1 {
            transition: all 0.3s linear;
            cursor: pointer;
            height: 49%;
            background: #fff;
            padding: 20px;
            display: flex;
            flex-direction: column;
            .new-item1-inner {
              display: flex;
              flex-direction: column;
              height: 100%;
            }
            .new-title {
              transition: all 0.3s linear;
              line-height: 33px;
              font-size: 18px;
              color: #000000;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            p {
              transition: all 0.3s linear;
              font-size: 14px;
              color: #666666;
              line-height: 26px;
              margin: 10px 0;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .new-time {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .new-lf {
                transition: all 0.3s linear;
                font-size: 34px;
                font-weight: 800;
                color: #999999;
                line-height: 33px;
              }

              .new-rg {
                transition: all 0.3s linear;
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                line-height: 33px;
              }
            }
          }

          .new-item1:hover {
            background: #208ee1;
            color: #fff;

            .new-title {
              color: #fff;
            }

            p {
              color: #fff !important;
            }
            .detail-icon {
              color: #fff;
              .icon {
                border: 1px solid #fff;
              }
            }

            .new-time {
              .new-lf,
              .new-rg {
                color: #fff;
              }
            }
          }
        }

        .main3 {
          width: 50%;
          margin-left: 20px;

          .new-item2:nth-child(1),
          .new-item2:nth-child(2) {
            margin-bottom: 10px;
          }

          div.new-item2 {
            transition: all 0.3s linear;
            display: flex;
            height: 32%;
            background: #fff;
            cursor: pointer;
            .new-item2-inner {
              display: flex;
              height: 100%;
            }

            .lf {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 100px;
              position: relative;

              .new-lf {
                transition: all 0.3s linear;
                font-size: 34px;
                font-weight: 800;
                color: #999999;
                line-height: 33px;
              }

              .new-rg {
                transition: all 0.3s linear;
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                line-height: 33px;
              }
            }

            .lf:after {
              position: absolute;
              right: 0;
              content: '';
              border-left: 1px solid #f3f3f3;
              height: 60%;
            }

            .rg {
              padding: 20px;
              display: flex;
              flex-direction: column;

              .new-title {
                transition: all 0.3s linear;
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                line-height: 33px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .btn-arrow {
                display: inline-block;
                width: 25px;
                height: 8px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                transition: all 0.6s ease-out 0s;
                position: relative;
                margin: 0 5px;
                background-image: url(../../assets/yskimg/home/title-arrow.png);
              }
            }
          }

          .new-item2:hover {
            background: #208ee1;
            color: #fff;

            .lf {
              .new-lf,
              .new-rg {
                color: #fff;
              }
            }

            .rg {
              .new-title {
                color: #fff;
                cursor: pointer;
              }
              .btn-arrow {
                background-image: url(../../assets/yskimg/home/hover-title-arrow.png);
              }
            }
          }
        }

        .flexcol {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
      }
    }
  }

  &.showdiv {
    .main {
      .main_bottom {
        .main_ul {
          .main1 {
            opacity: 1;
            transform: none;
          }

          .rg {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
  .newspic {
    position: relative;
    .title {
      position: absolute;
      bottom: 0;
      height: 40px;
      line-height: 40px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      width: 100%;
      padding: 0 10px;

      .titleinner {
        width: calc(100% - 150px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
