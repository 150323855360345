<template>
  <div class="tab-one">
    <div class="enterus-panel">
      <EnterusTitleLayout title="CONTACT  US" subtitle="联系我们"></EnterusTitleLayout>
      <div class="body">
        <div class="left" id="yzMap"></div>
        <div class="right">
          <div class="right_word" v-for="item, index in tellme" :key="index">
            <img :src=item.img alt="">
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnterusTitleLayout from '@/components/layout/EnterusTitleLayout';
export default {
  components: {
    EnterusTitleLayout,
  },
  data() {
    return {
      tellme: [
        { img: require("../../assets/yskimg/enterus/定位.png"), title: '地址：南京市栖霞区紫东创意园西区F4-2-310' },
        { img: require("../../assets/yskimg/enterus/电话.png"), title: '电话：025-52811889' },
        { img: require("../../assets/yskimg/enterus/邮箱.png"), title: '邮箱：njyskdlxx@sina.com' },
        { img: require("../../assets/yskimg/enterus/传真.png"), title: '传真：025-52811889' }
      ]
    };
  },
  created() { },
  mounted() {
    this.initmap()
  },
  methods: {
    initmap() {
      // const a = new Promise((resolve, reject) => {
      //   console.log(reject);
      //   // 如果已加载直接返回
      //   if (window.T) {
      //     console.log('地图脚本初始化成功...');
      //     resolve(window.T);
      //   }
      // });
      // // 第一种方式显示
      // a.then((T) => {
      //   const imageURL = 'http://t0.tianditu.gov.cn/img_c/wmts?tk=5228a6fb6f451e191672532de0a03ad6';
      //   const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
      //   const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
      //   const map = new T.Map('yzMap', config);
      //   const ctrl = new T.Control.MapType();
      //   map.addControl(ctrl);
      //   // const map = new T.Map('yzMap');
      //   map.centerAndZoom(new T.LngLat(116.401003, 39.903117), 16);
      // }).catch();
      // 第二种方式显示
      const map = new window.T.Map('yzMap');
      map.centerAndZoom(new window.T.LngLat(118.90202999999997, 32.08001), 16);

      let icon = new T.Icon({
        iconUrl: require("../../assets/yskimg/enterus/定位.png"),
        iconSize: new T.Point(30, 40),
        // iconAnchor: new T.Point(10, 56),
      });

      let markers = new T.Marker(new T.LngLat(118.90159555555555, 32.07970), {
        icon: icon,
      });
      map.addOverLay(markers);
      markers.id = 'corporation'
      //执行标记点函数
      // addwindowclick(markers, index);
    }
  },
};
</script>
<style lang="less" scoped>
/deep/.tdt-left {
  display: none;
}

.tab-one {
  .enterus-panel {
    padding-top: 60px;
    width: 100%;
  }

  .body {
    display: flex;
    padding-top: 60px;
    justify-content: center;

    .left {
      width: 675px;
      height: 510px;
    }

    .right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .right_word {
        display: flex;
        height: 110px;
        line-height: 110px;
        align-items: center;
        border-bottom: 1px solid #d9d9d9;

        >div {
          margin-left: 20px;
          font-size: 18px;
          color: #000;
        }
      }
    }
  }
}
</style>
