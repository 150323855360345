<template>
  <div class="main-body">
    <PageTopImgLayout imgurl="assets/yskimg/enterus/topimg.png">
      <div class="bg-top-layout">
        <div class="title fz36">联系我们</div>
        <div class="subtitle fz36">为您提供专业的业务支持</div>
        <div class="contact"><span>立即联系</span></div>
      </div>
    </PageTopImgLayout>
    <PanelLayout>
      <div class="content-body">
        <div class="top-header">
          <div class="tab-header">
            <span
              :class="activeMainLiID === item.id ? 'active' : ''"
              v-for="(item, index) in TabData"
              :key="index"
              @click="handleTabClick(item)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="tab-content">
          <Tabone v-show="activeMainLiID === 1"></Tabone>
          <Tabtwo v-show="activeMainLiID === 2"></Tabtwo>
          <Tabthree v-show="activeMainLiID === 3"></Tabthree>
          <Tabfour v-show="activeMainLiID === 4"></Tabfour>
          <Tabfive v-show="activeMainLiID === 5"></Tabfive>
          <Tabsix v-if="activeMainLiID === 6"></Tabsix>
        </div>
      </div>
    </PanelLayout>
  </div>
</template>

<script>
import PanelLayout from '@/components/layout/PanelLayout';
import PageTopImgLayout from '@/components/layout/PageTopImgLayout';
import TitleComponentLayout from '@/components/layout/TitleComponentLayout';
import Tabone from './tabone.vue';
import Tabtwo from './tabtwo.vue';
import Tabthree from './tabthree.vue';
import Tabfour from './tabfour.vue';
import Tabfive from './tabfive.vue';
import Tabsix from './tabsix.vue';
export default {
  components: {
    PanelLayout,
    PageTopImgLayout,
    TitleComponentLayout,
    Tabone,
    Tabtwo,
    Tabthree,
    Tabfour,
    Tabfive,
    Tabsix,
  },
  data() {
    return {
      activeMainLiID: 1,
      TabData: [
        { id: 1, name: '公司简介' },
        { id: 2, name: '荣誉资质' },
        { id: 3, name: '企业文化' },
        // { id: 4, name: '元时空风采' },
        { id: 5, name: '加入我们' },
        { id: 6, name: '联系我们' },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleTabClick(item) {
      this.activeMainLiID = item.id;
    },
  },
};
</script>
<style lang="less" scoped>
.main-body {
  padding-top: 100px;

  .bg-top-layout {
    width: 1100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-style: italic;

    .title {
      padding-bottom: 15px;
      position: relative;
    }

    .contact {
      font-size: 14px;
      margin-top: 30px;
      font-style: normal;

      span {
        display: inline-block;
        width: 110px;
        border: 1px solid #fff;
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .content-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .top-header {
      width: 100%;
      position: absolute;
      top: -89px;
      display: flex;
      justify-content: center;
    }

    .tab-header {
      width: 1100px;
      height: 90px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 27px 2px rgba(56, 56, 56, 0.1);

      span {
        height: 25px;
        font-size: 20px;
        cursor: pointer;
        border-radius: 2px;
        padding: 5px 10px;
        width: 100px;
        text-align: center;

        &.active {
          // background: #208ee1;
          color: #208ee1;
        }
      }
    }
  }

  .tab-content {
    width: 100%;
  }
}
</style>
