<template>
  <div class="tab-two">
    <div class="enterus-panel">
      <EnterusTitleLayout
        title="HONOR AND QUALIFICATION"
        subtitle="荣誉资质"
      ></EnterusTitleLayout>
      <div class="body">
        <div class="header fz16">
          <span
            :class="activeID === 4 ? 'active' : ''"
            @click="handleHeaderClick(4)"
            >资质与认证</span
          >
          <span
            :class="activeID === 1 ? 'active' : ''"
            @click="handleHeaderClick(1)"
            >奖项及资信</span
          >
          <span
            :class="activeID === 2 ? 'active' : ''"
            @click="handleHeaderClick(2)"
            >多项软件著作权</span
          >
          <!-- <span
            :class="activeID === 3 ? 'active' : ''"
            @click="handleHeaderClick(3)"
            >其他证书</span
          > -->
        </div>
        <div class="content" v-show="activeID === 1">
          <div class="pic-layout">
            <div class="pic pic1"></div>
            <div class="title">重合同守信用企业</div>
          </div>

          <div class="pic-layout">
            <div class="pic pic2"></div>
            <div class="title">质量服务诚信单位</div>
          </div>
          <div class="pic-layout">
            <div class="pic pic3"></div>
            <div class="title">重服务守信用企业</div>
          </div>
          <div class="pic-layout">
            <div class="pic pic4"></div>
            <div class="title">企业信用等级AAA</div>
          </div>
          <div class="pic-layout">
            <div class="pic pic5"></div>
            <div class="title">企业资信等级AAA</div>
          </div>
          <div class="pic-layout">
            <div class="pic pic6"></div>
            <div class="title">诚信经营示范单位</div>
          </div>
        </div>
        <div class="content2" v-show="activeID === 2">
          <div class="pic-layout">
            <div class="pic picc1"></div>
            <div class="title">元时空测绘成果云服务系统</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc2"></div>
            <div class="title">元时空测绘资料档案管理信息系统</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc3"></div>
            <div class="title">元时空应急测绘资源数据库管理系统</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc4"></div>
            <div class="title">元时空自然灾害风险防控和应急救援平台</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc5"></div>
            <div class="title">元时空地质环境监测与管理平台</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc6"></div>
            <div class="title">元时空灌区综合信息服务平台</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc7"></div>
            <div class="title">元时空智能问答机器人系统</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc8"></div>
            <div class="title">元时空测绘地理信息行业管理软件</div>
          </div>
        </div>
        <div class="content3" v-show="activeID === 3">
          <div class="pic-layout">
            <div class="pic pic1"></div>
            <div class="title">软件行业协会证书</div>
          </div>
        </div>
        <div class="content4" v-show="activeID === 4">
          <div
            style="display: flex; width: 100%; justify-content: space-around"
          >
            <div class="pic-layout-zz">
              <div class="piczz pic5"></div>
              <div class="title">软件行业协会会员</div>
            </div>
            <div class="pic-layout-zz">
              <div class="piczz pic6"></div>
              <div class="title">测绘资质证书</div>
            </div>
          </div>
          <div class="pic-layout">
            <div class="pic picc1"></div>
            <div class="title">质量管理体系认证证书</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc2"></div>
            <div class="title">职业健康安全管理体系认证证书</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc3"></div>
            <div class="title">环境管理体系认证证书</div>
          </div>
          <div class="pic-layout">
            <div class="pic picc4"></div>
            <div class="title">信息安全管理体系认证证书</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import EnterusTitleLayout from '@/components/layout/EnterusTitleLayout';
export default {
  components: {
    EnterusTitleLayout,
  },
  data() {
    return {
      activeID: 4,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleHeaderClick(id) {
      this.activeID = id;
    },
  },
};
</script>
<style lang="less" scoped>
.tab-two {
  .enterus-panel {
    padding-top: 60px;
    width: 100%;
  }
  .body {
    width: 1100px;
    margin: 0 auto;
    margin-top: 60px;
    .header {
      display: flex;
      justify-content: space-around;
      padding: 0 15%;
      span {
        display: inline-block;
        width: 150px;
        height: 50px;
        border-radius: 25px;
        line-height: 50px;
        background: #e2e2e2;
        text-align: center;
        cursor: pointer;
        &.active {
          color: #fff;
          background: #208ee1;
        }
      }
    }
    .content {
      margin-top: 60px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .pic-layout {
        padding: 10px;
        border: 1px solid #dcdcdc;
        margin-bottom: 10px;
        .title {
          text-align: center;
        }
      }
      .pic {
        width: 336px;
        height: 220px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.6s ease-out 0s;
        background-image: url('../../assets/yskimg/home/banner.png');
        margin-bottom: 20px;
      }
      .pic1 {
        background-image: url('../../assets/yskimg/enterus/重合同守信用企业.png');
      }
      .pic2 {
        background-image: url('../../assets/yskimg/enterus/质量服务诚信单位.png');
      }
      .pic3 {
        background-image: url('../../assets/yskimg/enterus/重服务守信用企业.png');
      }
      .pic4 {
        background-image: url('../../assets/yskimg/enterus/企业信用等级AAA.png');
      }
      .pic5 {
        background-image: url('../../assets/yskimg/enterus/企业资信等级AAA.png');
      }
      .pic6 {
        background-image: url('../../assets/yskimg/enterus/诚信经营示范单位.png');
      }
    }
    .content2 {
      margin-top: 60px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .pic-layout {
        padding: 10px;
        border: 1px solid #dcdcdc;
        width: 22%;
        margin-bottom: 10px;
        .title {
          text-align: center;
        }
      }
      .pic {
        width: 230px;
        height: 320px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.6s ease-out 0s;
        background-image: url('../../assets/yskimg/home/banner.png');
        margin-bottom: 20px;
      }

      .picc1 {
        background-image: url('../../assets/yskimg/enterus/元时空测绘成果云服务系统.png');
      }
      .picc2 {
        background-image: url('../../assets/yskimg/enterus/元时空测绘资料档案管理信息系统.png');
      }
      .picc3 {
        background-image: url('../../assets/yskimg/enterus/元时空应急测绘资源数据库管理系统.png');
      }
      .picc4 {
        background-image: url('../../assets/yskimg/enterus/元时空自然灾害风险防控和应急救援平台.png');
      }
      .picc5 {
        background-image: url('../../assets/yskimg/enterus/元时空地质环境监测与管理平台.png');
      }
      .picc6 {
        background-image: url('../../assets/yskimg/enterus/元时空灌区综合信息服务平台.png');
      }
      .picc7 {
        background-image: url('../../assets/yskimg/enterus/元时空智能问答机器人系统.png');
      }
      .picc8 {
        background-image: url('../../assets/yskimg/enterus/元时空测绘地理信息行业管理软件.png');
      }
    }
    .content3 {
      margin-top: 60px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .pic-layout {
        padding: 10px;
        border: 1px solid #dcdcdc;
        margin-bottom: 10px;
        .title {
          text-align: center;
        }
      }
      .pic {
        width: 420px;
        height: 280px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.6s ease-out 0s;
        background-image: url('../../assets/yskimg/home/banner.png');
        margin-bottom: 20px;
      }
      .pic1 {
        background-image: url('../../assets/yskimg/enterus/软件行业协会证书.png');
      }
    }
    .content4 {
      margin-top: 60px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .pic-layout {
        padding: 10px;
        border: 1px solid #dcdcdc;
        width: 22%;
        margin-bottom: 10px;
        .title {
          text-align: center;
        }
      }
      .pic-layout-zz {
        padding: 10px;
        border: 1px solid #dcdcdc;
        width: auto;
        margin-bottom: 10px;
        .title {
          text-align: center;
        }
      }
      .pic {
        width: 230px;
        height: 320px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.6s ease-out 0s;
        background-image: url('../../assets/yskimg/home/banner.png');
        margin-bottom: 20px;
      }

      .picc1 {
        background-image: url('../../assets/yskimg/enterus/质量管理体系认证证书.png');
      }
      .picc2 {
        background-image: url('../../assets/yskimg/enterus/职业健康安全管理体系认证证书.png');
      }
      .picc3 {
        background-image: url('../../assets/yskimg/enterus/环境管理体系认证证书.png');
      }
      .picc4 {
        background-image: url('../../assets/yskimg/enterus/信息安全管理体系认证证书.png');
      }
      .piczz {
        width: 420px;
        height: 280px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.6s ease-out 0s;
        background-image: url('../../assets/yskimg/home/banner.png');
        margin-bottom: 20px;
      }
      .pic5 {
        background-image: url('../../assets/yskimg/enterus/软件行业协会证书.png');
      }
      .pic6 {
        background-image: url('../../assets/yskimg/enterus/测绘资质.png');
      }
    }
  }
}
</style>
