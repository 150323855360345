<template>
  <div class="tab-one">
    <div class="solution-panel">
      <SolutionTitleLayout
        title="背景介绍"
        subtitle="Background"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着经济社会发展步入新时代，原有基础测绘在产品形式、技术方法、管理方式等方面的问题日益凸显。一方面，现有基础测绘成果执行的标准是模拟纸质地形图标准，对现实世界是分要素、分尺度抽象表达，尽管人眼可识别，但机器难理解，空间分析与决策局限性较大；此外，由于现有管理体制过分强调分级管理，一定程度影响了国、省、市县数据共享。另一方面，随着大数据、云计算、
        人工智能等技术与测绘的深度融合，对基础测绘成果的需求发生了变化，要求提供“实体化、
        三维化、 语义化、结构化、全空间、人机兼容”的地理信息产品。
        因此，基础测绘亟待转型升级、创新发展，建立起新型的基础测绘体系，全面提升其保障服务能力和水平。
      </p>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout title="愿景" subtitle="Vision"></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新型基础测绘是新时期面向基础测绘的新任务和新需求，在保持基础测绘公益性要求前提下，以重新定义基础测绘成果模式作为核心和切入点，带动技术体系、生产组织体系和政策标准体系全面升级转型的基础测绘体系。
      </p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新型基础测绘建设的中长期目标为：建成以地理实体为核心、“一库多能、按需组装”的国家基础地理实体数据库，经数据提取、适配组装、融合表达，构建“实景三维中国”。
      </p>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="总体思路"
        subtitle="General Idea"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新型基础测绘与实景三维的总体思路，是以地理实体为核心构建实景三维中国，推动传统基础地理数据库升级为基础地理实体数据库，通过时空大数据平台，面向不同的应用场景，按需提取出地理实体数据、相关联的专题数据、业务数据等，组装形成地理应用场景产品并对外提供。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabone/tabone1.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="地理实体设计"
        subtitle="Geographic Entity Design"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品的转型是从制图表达到空间治理转变。现有基础测绘成果对现实世界是分要素、分尺度、割裂式地进行抽象表达，无法关联大数据，难以参与空间分析与决策。因此，新型基础测绘提出了“实体化、多维化、语义化、结构化、一体化”的地理实体数据产品需求，以适应新时代要求，全面提升保障服务能力和水平。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabone/tabone2.png')" />
      </div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;元时空根据对地理实体的概念与定义、数据模型以及分类等内容的研究，从数据范畴、数据分类、数据内容、数据编码设计和数据表达等角度进行地理实体的产品设计，并制定地理实体数据标准和地理实体数据生产规程。
      </p>
      <!-- Geographic Entity Data Categories -->
      <div class="title introduce fz22">
        <span class=""></span>地理实体数据范畴
      </div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过梳理自然资源和智慧城市各行业管理对象，并参考国家现行法律法规等相关文件，确定地理实体的数据范畴，即新型基础测绘所需做的地理实体。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabone/tabone3.png')" />
      </div>
      <!-- Geographic Entity Data Classification -->
      <div class="title introduce fz22">
        <span class=""></span>地理实体数据分类
      </div>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabone/tabone5.png')" />
      </div>
      <!-- Geographic Entity Data Content -->
      <div class="title introduce fz22">
        <span class=""></span>地理实体数据内容
      </div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;依据自然资源部国土测绘司印发的《新型基础测绘与实景三维中国技术文件-2分类粒度精度》，将基础地理实体分为自然地理实体、人工地理实体和管理地理实体三大类。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabone/tabone4.png')" />
      </div>
      <!-- Geographical Entity Identification Code -->
      <div class="title introduce fz22">
        <span class=""></span>地理实体标识码
      </div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;包括位置码和身份码，位置码作为地理实体空间定位和空间索引的标识码，实现地理实体快速空间定位;身份码作为地理实体唯一身份标识，用于地理实体数据组织管理、对外共享交换，实现数据关联融合。
      </p>
      <!-- Geographic Entity Data Representation -->
      <div class="title introduce fz22">
        <span class=""></span>地理实体数据表达
      </div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;采用“一码多态”的数据表达模式，即一个地理实体对象+多种空间表达形态，满足不同应用场景对同一实体对象的实际需求。
      </p>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="生产组织优化"
        subtitle="Production organization optimization"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="title introduce fz22">
        <span class=""></span>现有数据实体化改造工艺
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>实体化改造工艺流程
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;按照地理实体产品设计，从现有的数据源〈如基本比例尺数字地形图数据、第三次全国国土调查数据、地理国情数据等）中抽取转换、编辑生产地理实体矢量数据，再对现有的遥感影像数据、倾斜摄影三维模型数据、精细化手工模型数据等进行实体化改造。
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabone/优势特点1.png')"
          />
        </div>
      </div>
      <div class="title introduce fz22">
        <span class=""></span>地理实体生产工具
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>地理实体矢量数据生产
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;元时空地理实体生产工具内置各地数据规范10余种和完整的质检规范，内置10种标识码编码方案并可按需自由组合，定制了超过130种图形、属性编辑能力，数据生产效率提升在3倍以上。
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabone/优势特点2.png')"
          />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>地理实体遥感影像数据
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;按照地理实体的矢量范围，通过参数设置对遥感影像数据进行实体化，实现从“大合照”到“个人照”的转变，解决传统遥感影像数据“—张皮”相互粘连的问题。
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabone/优势特点3.png')"
          />
        </div>
      </div>
      <div class="title introduce fz22">
        <span class=""></span>数据更新机制优化
      </div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提出主动式数据变化更新机制，包括政务活动驱动数据联动更新、遥感影像变化检测更新及众源测绘智能更新三种方式。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabone/优势特点5.png')" />
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>主动式数据更新管理系统
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主动式数据更新管理系统建立在优化的数据更新机制下，对整个数据更新流程进行全方位的管控，提升数据更新的实效性与准确性。
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabone/优势特点6.png')"
          />
        </div>
      </div>
      <p class="bottomdistance"></p>
    </div>
  </div>
</template>

<script>
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {
      data: [],
      activeMainLiID: 1,
    };
  },
  created() {},
  mounted() {
    if (this.data.successcase) {
      this.getBanner();
    }
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container1', {
        direction: 'horizontal',
        slidesPerView: 2,
        spaceBetween: 30, //轮播图之间的间距
        loop: true,
        autoplay: true, //可选选项，自动滑动
        // navigation: {
        //   nextEl: '.swiper-button-next1',
        //   prevEl: '.swiper-button-prev1',
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tab-one {
  .tabimg {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  .topdistance {
    margin-top: 60px;
  }
  .bottomdistance {
    margin-bottom: 60px;
  }
  .introduce {
    line-height: 48px;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
    // .swiper-button-prev,
    // .swiper-button-next {
    //   width: 30px;
    //   height: 60px;
    //   background: #c7c7c7;
    //   color: #fff;
    // }
  }
  .plus {
    .title {
      .sufixx {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        margin-right: 10px;
      }
    }
  }
  .title {
    .sufixx {
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #000;
      margin-right: 10px;
    }
  }
}
</style>
