<template>
  <div class="tab-one">
    <div class="enterus-panel">
      <EnterusTitleLayout
        title="CORPORATE CULTURE"
        subtitle="企业文化"
      ></EnterusTitleLayout>
      <div class="body">
        <div class="left">
          <div class="item" v-for="(item, index) in curturelist" :key="index">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/yskimg/enterus/团队风采.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnterusTitleLayout from '@/components/layout/EnterusTitleLayout';
export default {
  components: {
    EnterusTitleLayout,
  },
  data() {
    return {
      curturelist: [
        {
          title: '诚信（SINCERITY）:',
          content: '重诺贵和，立诚守信，人无信而不立，企无信不强。',
        },
        {
          title: '责任（RESPONSIBILITY）:',
          content: '无论是对客户还是对社会，我们都会牢记责任二字。',
        },
        {
          title: '高效（EFFICIENT）:',
          content: '快捷高效为客户服务是我们的宗旨和原则。',
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-one {
  .enterus-panel {
    padding-top: 60px;
    width: 100%;
  }

  .body {
    display: flex;
    padding-top: 60px;
    justify-content: center;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item {
        margin-bottom: 15px;
        .title {
          font-weight: 700;
          color: #218ee3;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }

    .right {
      margin-left: 100px;
      background: pink;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
