<template>
  <div class="header">
    <!-- 顶部 -->
    <!-- <div class="h_top"></div> -->
    <!-- logo部分 -->
    <!-- <div class="h_logo">
      logo
      <div class="left">
        <a href="/">
          <img src="../../assets/yxtx/logo.png" alt="" />
        </a>
      </div>
      搜索框
      <el-button @click="onSearch">查询</el-button>
      <div class="right demo-input-suffix">
        <el-input
          placeholder="请输入关键字进行搜索"
          clearable
          v-model="searchInput"
          @keyup.enter.native="onSearch"
        >select
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div> -->
    <!-- 导航栏 -->
    <!-- <div class="h_nav"> -->
    <!-- <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      router
    >
      <div class="nav_item">
        <el-menu-item
          v-for="(item, index) in category"
          :key="index"
          :index="item.description"
          :class="{ on: currentIndex === index }"
          @click="changeIndex(index)"
        >
          <span slot="title">{{ item.name }}</span>
          <ul class="second_menu">
            <li
              v-for="i,idx in item.children"
              :key="i.id"
              href="#"
              @click.stop="toPage(i.description)"
              @click="changeIndex(index)"
            >
              <a>{{ i.name }}</a>
            </li>
          </ul>
        </el-menu-item>
      </div>
    </el-menu> -->


    <div style="width: 100%;">
      <div style="display: flex;justify-content: space-between; height: 100px;width: 1600px;margin: 0 auto;">
        <div>
          <a href="/"><img src="../../assets/yxtx/logo.png" alt="" /></a>
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-demo nav_item" mode="horizontal" style="width: 800px;">
          <el-menu-item style="line-height: 100px;height: 100%;width: 20%;" v-for="(item, index) in category"
            :key="index" :index="index + 1">
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
        <div
          style="border-radius:5px ; background-color: #208de0;color: #fff;font-weight: 700;height: 30px;width: 150px;text-align: center;line-height: 30px;margin-top: 35px;">
          <i class="el-icon-phone-outline"
            style="margin-right: 10px;font-size: 20px;line-height: 30px;"></i>025-52811889
        </div>
      </div>
    </div>


  </div>
  <!-- </div> -->
</template>
<script>
import { get } from "../../utils/request";
export default {
  inject: ["reload"],
  data() {
    return {
      activeIndex: '1',
      searchInput: "",
      // 所有一级栏目
      category: [
        { name: '首页' },
        { name: '新闻动态' },
        { name: '业务领域' },
        { name: '解决方案' },
        { name: '走进元时空' },
      ],
      currentIndex: "",
    };
  },
  created() {
    // this.getFirstCategory();
    // this.getSecondCategory();
  },
  computed: {},
  methods: {
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    changeIndex(index) {
      this.currentIndex = index;
    },
    // 获取一级栏目信息
    getFirstCategory() {
      get("/index/category/findCategoryTree").then((res) => {
        if (res.status == 200) {
          // 父栏目
          this.category = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // 获取二级信息
    getSecondCategory() {
      this.category.forEach((item) => {
        var parentId = item.id;
        get("/index/category/findByParentId", { parentId }).then((res) => {
          if (res.status == 200) {
            item.child = res.data;
          }
        });
      });
    },
    // 跳转路由
    toPage(path, index) {
      this.$router.push(path);
    },
    // 根据关键字查找文章
    onSearch() {
      this.$router.push({
        path: "/searchPage",
        query: {
          title: this.searchInput,
        },
      });
      if (this.$router.path != '/home') {
        this.reload();
      }
    }
  },
};
</script>
<!-- <style lang='less' scoped>
.on {
  color: #ffcf46;
  background: #680034;
}

.header {
  width: 100%;

  .h_top {
    width: 100%;
    height: 30px;
    background: #680034;
  }

  .h_logo {
    width: 1190px;
    height: 100px;
    margin: 0 auto;

    .left {
      float: left;

      img {
        margin-top: 10px;
      }
    }

    .right {
      float: right;
      width: 275px;
      margin-top: 30px;

      /deep/.el-input .el-input__inner {
        border-radius: 25px;
        height: 35px;
        font-size: 13px;
      }

      /deep/.el-input .el-input__icon {
        width: 30px;
        line-height: 36px;
        font-size: 17px;
      }

      /deep/ .el-input--prefix .el-input__inner {
        padding-left: 35px;
      }
    }
  }

  .el-menu.el-menu--horizontal {
    border-top: solid 1px #e6e6e6;
    height: 50px;
    border-bottom: none;
    z-index: 9;
    box-shadow: 0 3px 3px #c0c0c0;
  }

  .el-menu-demo .nav_item {
    width: 1220px;
    margin: 0 auto;
  }

  .el-menu-demo {
    .el-menu-item.is-active {
      color: #ffcf46;
      background: #680034;
    }

    .nav_item>li {
      padding: 0;
      width: 108px;
      height: 50px;
      line-height: 50px;
      float: left;
      text-align: center;
    }

    .nav_item>li:last-child {
      width: 125px;
    }

    .nav_item>li:hover {
      // background: #680034;
      color: #ffcf46;
    }

    .nav_item>li:hover ul {
      display: block;
    }

    .second_menu {
      position: absolute;
      top: 50px;
      width: 100%;
      left: 0;
      display: none;
      background-color: #fff;

      li {
        border-bottom: 1px solid #b5b5b5;
      }

      li:last-child {
        border-bottom: none;
      }

      li:hover>a {
        color: #ffcf46;
      }
    }
  }
}
</style> -->
<style lang="less" scoped>


.el-menu.el-menu--horizontal {
  border: 0;
   >.el-menu-item{
    text-align: center;
   }
}
.el-menu--horizontal > .el-menu-item.is-active{
  
}
</style>

