<template>
  <div class="tab-five">
    <div class="solution-panel">
      <SolutionTitleLayout
        title="背景介绍"
        subtitle="Background"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        以习近平同志为核心的党中央高度重视网络安全和信息化工作，党的十九大要求全面建设数字政府，创新行政方式，提高行政效能，建设人民满意的服务型政府。按照自然资源部信息化整体战略部署，各级自然资源部门要坚持创新引领、应用导向，全面推动新一代信息技术与自然资源“两统一”管理深度融合，建成数字自然资源“一张网、一张图、一平台与三大应用体系”，构建自然资源动态监测与态势感知能力，夯实“数据驱动、精准治理”监管决策机制，完善自然资源政务审批“一件事”服务模式，实现全域全要素全周期自然资源管理的数字化、网络化和智能化，提升国土空间治理能力，优化国土空间开发格局，服务经济社会环境全方位高质量发展。
      </p>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="核心能力"
        subtitle="Core Competencies"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>自然资源三维立体“一张图”
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          以实景三维建设成果作为自然资源三维立体“一张图”的三维空间框架，在充分掌握基础地理数据和自然资源要素空间、位置、形态、关系等特征的基础上，形成一整套规范化、标准化、精细化的工作“底图”;通过重构数据生产、管理和更新模式，汇聚、整合、联通各类规划数据、管理数据及社会经济数据，强化数据资源“管用”分离、动态更新，形成时空一致、三维立体、多库合一、业务贯通的自然资源三维立体“一张图”，打造“测绘为基、地上地下、陆海相连”的数据体系，实现时空数据全面化、数据聚合化和信息实体化。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/one.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/two.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>国土空间基础信息平台
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          将国土空间基础信息平台打造成为自然资源信息化“资源中心+能力中心”，提升国土空间治理能力现代化水平，最大限度地挖掘和释放国土空间数据资源的潜力和潜能。一是统筹云计算、大数据、区块链、数字孪生、人工智能、搜索引擎等技术方法，为自然资源各类应用提供技术能力支撑;二是统筹业务数据、空间分析、统计分析、业务规则及决策模型，为自然资源调查监测评价、空间规划、管制利用、生态修复、综合执法等应用提供业务能力支撑。三是提供自然资源数据治理能力，实现数据管理、资产管理、元数据管理，建立数据关联，解决数据互联互通问题，形成自然资源时空大数据图谱。
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          同时，平台还肩负着信息交换中心的职责。纵向联通自然资源部以及省、市、县自然资源管理部门，实现数据汇交与回流;横向与大数据局、发改、环保、住建、交通、水利、农业等部门实现信息交换与共享。在自然资源实体对象的基础上，实现外部数据的关联，逐步形成以自然资源管理为对象的“块数据”，为自然资源治理体系提供智能化基础。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/three.png')" />
        </div>
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="深耕业务"
        subtitle="Deeply Cultivate Business"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="title introduce fz22">
        <span class=""></span>自然资源监管决策应用体系
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>国土空间规划“一张图”实施监督
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          立足空间规划治理体系与治理能力现代化的目标要求，基于自然资源云基础设施体系，以统一的标准规范、技术规则、管理机制、信息安全体系为保障，以自然资源“一张图”大数据体系为基础，在国土空间基础信息平台的支撑下，构建国土空间规划“一张图”实施监督应用体系。全面夯实规划编制智能分析能力、规划传导落实审查能力、精准实施管控治理能力与监测评估预警反馈能力，面向各级政府部门、事业单位、科研院所和社会公众，提供规划编制评价-核查审批-实施管控-监测评估信息服务，全面支撑空间规划、用途管制、开发利用、执法督察等各类业务管理所需的图数服务。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/four.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/five.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>建设用地批后监管
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          建设用地批后动态监管系统为自然资源管理部门提供建设用地批、供、用整合管理平台，能无缝对接征地、供地、用地数据，对国有建设用地的开竣工、出让金缴纳、投资强度、产值税收、低效用地、闲置土地、土地开发建设情况进行动态跟踪监督管理。系统依托最新电子地图、高分辨影像图、批供图层、国土“一张图”业务数据为基础，空间图形为表现形式，实现“以图查地”、“以图管地”、“以图执法”，加强了国有建设
          用地全生命周期的管理，促进了土地出让合同的有效履行，提高了土地利用的质量和效益。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/six.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/seven.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/eight.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>自然资源资产审计
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          为贯彻落实党中央加快推进生态文明建设的要求，切实履行自然资源资产管理和生态环境保护职责，吉奥时空构建了“多端联动、协同审计”的自然资源资产审计系统，提供数据采集聚合、疑点智能识别、业务协同办理的全流程审计服务，满足天地网一体数据实时采集提取与业务审批数据校核;通过对资源资产变化进行监测预警、关联分析、异常追溯与趋势研判，揭示资源资产开发利用和生态环境保护中存在的突出问题和风险隐患，为保障自然资源资产节约利用和生态环境安全提供技术支撑和决策依据。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/nine.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/ten.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>自然资源综合执法
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          充分利用卫星遥感、无人机、高塔视频、物联网等终端，结合AI智能、大数据、移动互联网等技术手段，聚焦“天上看、空中探、地上查”目标，搭建全覆盖、全要素、一体化的执法监察感知网络;完善违法行为立体化发现渠道和处置模式，建立快捷有效的核查指挥和“早发现、早制止、严查处”的工作机制;结合卫片执法监察、耕地“非粮化”“非农化”等自然资源综合执法应用场景，赋能违法线索进行精准定位、智能分析，解决发现难、判断难、处置难的问题;通过省、市、区县多级联动，保障执法部门和自然资源管理相关业务部门的信息互通，实现与其他政府部门业务系统信息共享;推动执法重心下移、关口前移，有效提升执法监管的科学性和时效性，夯实自然资源空间治理能力，为实现自然资源科学合法、合理利用提供有力监管。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/even1.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/even2.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>测绘地理信息行业监管
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          为深入贯彻习近平新时代中国特色社会主义思想和党的十九大精神，全面落实深化“放管服”改革和优化营商环境要求，测绘地理信息主管部门持续放宽测绘市场准入门槛，从“严进宽管”开始转向“宽进严管”，与此同时经济全球化、社会信息化的深入发展也推动了测绘装备和测绘信息技术的高速发展，吸引了大量技术人员和企业加入到测绘地理信息行业。
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          为进一步规范全省测绘市场秩序，推动行业高质量发展，全面提升服务水平，强化测绘活动的事中事后监管，省测绘地理信息主管部门提出以“强化数字政务，提高监管效能”为目标，通过信息化技术手段对全省测绘资质单位、项目招投标、项目备案、市场信用、成果汇交、质量检验、地理信息安全等方面进行实时动态管理，打造全省统一的测绘监管与服务一体化新模式，构建面向各级测绘主管部门和测绘资质单位的管理与服务平台，实现测绘项目从中标、备案到启动实施、成果汇交、质量检查和成果应用的全生命周期管理。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabfive/twen.png')" />
        </div>
      </div>
      <div class="title introduce fz22">
        <span class=""></span>测绘成果管理与应用
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>测绘地理信息档案管理
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          加强测绘地理信息资料档案管理，为国民经济建设、国防建设和社会发展提供及时、可靠的基础服务，是测绘资料档案管理部门的重要职责之一。因此，建立测绘地理信息档案资料综合管理系统，利用信息化的手段对其档案进行管理和应用，提高测绘地理信息档案资料综合服务能力具有现实的必要性和紧迫性。元时空测绘档案管理系统由时空档案资源库与档案业务管理系统组成。系统数据库具有以元数据为核心、框架统一、逻辑一致、分层管理的特点，在确保数据安全的前提下，系统建设科学、规范和实用，实现了测绘地理信息档案的图形化，库房的虚拟可视化，数字与实体档案的在线管理，以及档案业务“进管出”的一体化
          管理等功能。
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 系统特点
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          （1）基于OAIS参考模型的测绘档案全生命周期管理
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用1.png')"
          />
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用2.png')"
          />
        </div>

        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          （2）基于空间位置的多源异构测绘资料档案管理
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用3.png')"
          />
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          （3）基于真三维虚拟仿真技术的库房管理
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用4.png')"
          />
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用5.png')"
          />
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （4）灵活便捷的档案模版管理配置
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用6.png')"
          />
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabfive/测绘成果应用7.png')"
          />
        </div>
        <div class="plus">
          <div class="title introduce fz18">
            <span class="sufixx"></span>测绘成果服务云平台
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            测绘与地理信息成果服务云平台的建设,从测绘成果数据分发利用环节提升服务质量,满足公众在线测绘成果申请需求,并建立省、市、县一体的测绘成果检索、订单初定、在线审批、数据处理、成果分发全流程测绘成果和订单信息流转的跨网络下单、审批、分发服务体系;同时,为适应测绘与地理成果分发服务多场所办公的需求,平台能够支持分布式部署,通过简单配置,达到功能模块自由定制的要求。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            便捷化搜索：系统采用内存数据库+“属性/空间”一体化的空间要素多级索引,满足高并发网络环境下查询的“秒级"响应;采用智能检索.引导式查询与图文互查技术,快速便捷查询成果。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            电商订单模式成果申请：借鉴电子商务平台服务模式,采用大众熟知的“成果车”方式存储用户意向成果,以订单形式捉交成果申请。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            省市县一体化测绘成果服务提供：系统采用集中的分级管理模式,各级用户负责维护、审批、分发各自的数据,同时可汇交给上级用户。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            店铺式分站点管理：采用店铺式分站点管理新模式,用户利用系统模块,可对站点页面、成果类型、站点功能等进行灵活配置。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            二维码内外网订单信息流转：创新性的采用二维码作为载体,既满足网络管理的要求,又能便利的完成两套网络间信息的传输。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            多平台、多客户端支持,随时随地在线审批：采用HTML5技术构建,同时支持APP端在线审批。
          </div>
          <div class="content introduce fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            数据交换与开放的接口：系统提供多种数据交换共享的接口,从而保证与其他系统之间信息共享。
          </div>
          <div class="tabimg">
            <img
              :src="
                require('@/assets/yskimg/solution/tabfive/测绘成果服务云平台1.png')
              "
            />
          </div>
          <div class="tabimg">
            <img
              :src="
                require('@/assets/yskimg/solution/tabfive/测绘成果服务云平台2.png')
              "
            />
          </div>
        </div>
        <p class="bottomdistance"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {
      data: Resultjson.data.TabData[4],
    };
  },
  created() {},
  mounted() {
    if (this.data.successcase) {
      this.getBanner();
    }
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container5', {
        direction: 'horizontal',
        slidesPerView: 2,
        spaceBetween: 30, //轮播图之间的间距
        loop: true,
        autoplay: true, //可选选项，自动滑动
        observer: true,
        observeParents: true,
        // navigation: {
        //   nextEl: '.swiper-button-next1',
        //   prevEl: '.swiper-button-prev1',
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tab-five {
  .tabimg {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  .topdistance {
    margin-top: 60px;
  }
  .bottomdistance {
    margin-bottom: 60px;
  }
  .introduce {
    line-height: 48px;
  }
  .solutionimg {
    margin: 60px 0;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        width: 50%;
      }
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          // background-position: center center;
          // background-repeat: no-repeat;
          background-size: cover;
          // background-size: 100% 100%;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
    // .swiper-button-prev,
    // .swiper-button-next {
    //   width: 30px;
    //   height: 60px;
    //   background: #c7c7c7;
    //   color: #fff;
    // }
  }
  .plus {
    .title {
      .sufixx {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        margin-right: 10px;
      }
    }
  }
}
</style>
