<template>
  <div class="out_Container">
    <div class="n_tit" style="margin-top:15px" v-if="article.category">
      <span class="title">当前位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to=article.category.description
          >{{ article.category.name }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>正文</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="contain_con">
      <h2>{{ article.title }}</h2>
      <span class="tip"
        >【来源: | 发布日期：{{ article.publishTime | fmtDate_global }} 】
      </span>
      <div class="content" v-html="article.content">
        {{ article.content }}
      </div>
      <div class="con_bm cleafix">
        <a
          href="javascript:window.opener=null;window.open('','_self');window.close();"
          class="close"
          >关闭</a
        >
        <a href="javascript:window.print()" class="dy">打印</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      id: undefined,
    };
  },

  created() {
    this.id = this.$route.query.id;
    var obj = {
      id: this.id,
    };
    this.getArticle(obj);
  },
  computed: {
    // 引入获取文章对象
    ...mapState("findArticle", ["article"]),
  },
  methods: {
    // 引入文章数据
    ...mapActions("findArticle", ["getArticle"]),
    
  },
};
</script>
<style lang='less' scoped>
</style>