<template>
  <div class="pageimg-layout">
    <div class="news-topimg">
      <img :src="require('@/' + imgurl)" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['imgurl'],
  name: 'PageTopImgLayout',
};
</script>
<style lang="less" scoped>
.pageimg-layout {
  position: relative;
  width: 100%;
  .news-topimg {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
