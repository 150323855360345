<template>
  <div class="out_Container">
    <div class="n_left">
      <h2>学院简介</h2>
      <ul class="n_nav">
        <li><a href="#" @click.prevent="toPage('/introduction')">学院概况</a></li>
        <li><a href="#" @click.prevent="toPage('/introduction/leader')">学院领导</a></li>
        <li><a href="#" @click.prevent="toPage('/introduction/history')"  class="cur">历史沿革</a></li>
      </ul>
    </div>
    <div class="n_right">
      <div class="n_tit">
        <span class="title">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/introduction' }">学院简介</el-breadcrumb-item>
          <el-breadcrumb-item>历史沿革</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1 class="Title TitleLong">
        <span class="tit">历史沿革</span>
        <span class="line">
          <b class="line_l"></b>
          <b class="line_s"></b>
        </span>
      </h1>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  created() {
    
  },
  methods: {
    toPage(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less">
// .nav_item > li{
//   color: #303133;
//   background: #fff;
// }
// .nav_item > li:nth-child(2) {
//   color: #ffcf46;
//   background: #680034;
// }
</style>