<template>
  <div class="tab-one">
    <div class="enterus-panel">
      <EnterusTitleLayout
        title="COMPANY PROFILE"
        subtitle="公司简介"
      ></EnterusTitleLayout>
      <div class="body">
        <div class="introduce">
          <div class="title fz34">南京元时空地理信息技术有限公司</div>
          <p class="content fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ introduce }}
          </p>
          <p class="content fz18">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ introduce1 }}
          </p>
        </div>
        <div class="pic"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import EnterusTitleLayout from '@/components/layout/EnterusTitleLayout';
export default {
  components: {
    EnterusTitleLayout,
  },
  data() {
    return {
      introduce: `南京元时空地理信息技术有限公司坐落于南京市栖霞区，是一家专注于GIS信息化平台建设的软件企业，公司先后获得了乙级测绘资质、双软企业、国家3A信用企业、IS09001、ISO27001体系认证等资质，拥有30余项软件著作权，依托自主核心产品和综合集成应用技术，为空间智能、大数据、云计算时代提供地理信息综合应用服务。经过多年的发展，公司已成为组织架构规范、管理模式先进、人才结构合理、专业种类齐全、技术装备精良、具有较强行业影响力的地理信息产业优秀单位。`,
      introduce1: `公司具有测绘与地理信息工程、行业软件定制开发、物联网监测监控、系统集成、调查监测和数据处理分析服务等综合业务能力。元时空GIS系列产品广泛应用于测绘、国土、规划、城管、公安、水利、交通、电力、农业、林业、党建等行业。元时空依托业内领先、自主可控的GIS平台系列软件，打造空天地智能一体化数字孪生大数据平台，面向各领域提供GIS行业产品和解决方案。`,
      activeMainLiID: 1,
      datalist: Resultjson.data.datalist,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-one {
  .enterus-panel {
    padding-top: 60px;
    width: 100%;
  }
  .body {
    display: flex;
    .introduce {
      width: 65%;
      margin-right: 5%;
      font-size: 18px;
      line-height: 48px;
      // margin: 60px 0;
      .title {
        font-weight: 500;
        color: #1a2447;
        line-height: 33px;
      }
      .content {
        margin-top: 30px;
      }
    }
    .pic {
      margin-top: 30px;
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all 0.6s ease-out 0s;
      background-image: url('../../assets/yskimg/enterus/companybuilding.png');
    }
  }
}
</style>
