<template>
  <div class="tab-one">
    <div class="solution-panel">
      <SolutionTitleLayout
        title="背景介绍"
        subtitle="Background"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabtwo/one.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="建设目标"
        subtitle="Construction Goals"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结合国土空间规划工作信息化要求,充分运用新—代信息技术，以国土三调数据基础，整合集成各类现状底图数据、各级各类国土空间规划数据与自然资源业务管理数据，形成权威统―的自然资源—张图；以目标、问题和可操作为导向，基于国土空间基础信息平台，搭建省-市-县联动的国土空间规划"—张图"实施监督信息系统，形成完善的业务应用体系，全面夯实规划编制智能分析能力、规划传导落实审查能力、精准实施管控治理能力与监测评估预警反馈能力。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabtwo/two.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="建设内容"
        subtitle="Construction Content"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabtwo/three.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="总体框架"
        subtitle="Overall Framework"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;立足空间规划治理体系与治理能力现代化的目标要求，基于自然资源云基础设施体系，以统一的标准规范、技术规则、管理机制、信息安全体系为保障，以自然资源一张图大数据体系为基础，在国土空间基础信息平台的支撑下，构建国土空间规划"—张图"实施监督应用体系，面向各级政府部门、事业单位、科研院所和社会公众提供规划编制评价-核查审批-实施管控-监测评估信息服务，全面支撑空间规划、用途管制、开发利用、执法督察等各类业务管理所需的图数服务。
      </p>
      <div class="tabimg">
        <img
          :src="
            require('@/assets/yskimg/solution/tabtwo/onemapapplication1.jpg')
          "
        />
        <img
          :src="
            require('@/assets/yskimg/solution/tabtwo/onemapapplication2.jpg')
          "
        />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="系统特色"
        subtitle="System Features"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/tabtwo/four.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="建设内容"
        subtitle="Construction Content"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;国土空间规划"一张图"实施监督信息系统面向自然资源和规划业务部门、各政府部门和编制单位。系统基于国土空间基础信息平台搭建，包括一张图、规划分析评价、成果审查管理、编制管理、监测评估预警、资源环境承载能力监测预警、用途管制、指标模型管理等模块;系统纵向与国家、省级"一张图"实施监督信息系统联通衔接，横向为各类业务审批系统提供权威统一的一张图数据服务。
      </p>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>数据转换与入库
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数据转换与入库系统分为CAD质检、GIS质检与数据转换三大部分，包含多规检测、单一规划检测与多版本检测。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/five.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>成果辅助审查与管理
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以任务为导向，针对国土空间规划和相关专项规划的规划成果进行数据标准合规性层面的质量检查，并提供图文一致性、图数一致性、指标符合性、空间一致性等检查功能，生成审查报告。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/six.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>一张图应用
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以国土三调数据为基础，整合集成基础地理数据，开展地类细化、地类衔接和补充调查，汇集各部门空间关联数据，扩充社会经济数据，形成坐标—致、边界吻合、上下贯通的现状底图。根据国土空间规划数据库标准和汇交要求、质检细则与接口规范，整合各级各类国土空间规划数据，集成展示现状底图、规划蓝图、管理用图和社会经济数据等服务资源，提供资源浏览、专题图制作、对比分析、查询统计与成果共享等功能，支撑规划编制-审查-管控-监督-评估各环节看图、用图、审图、制图等业务应用。
        </div>
        <div class="tabimg">
          <img
            :src="require('@/assets/yskimg/solution/tabtwo/国土空间规划.png')"
          />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>指标模型管理
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;依据国土空间开发保护现状评估工作要求，以自然资源部下发的28个基础指标为重点，结合推荐指标建立符合当地实际，形成反应当地特点的科学有效、便于操作的全覆盖、可落实评估指标体系，采用客观真实的数据及可靠的分析方法，对市县进行定期体检，找准问题，提出对策，形成评估报告。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/seven.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>规划编制管理
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;面向国土空间规划管理部门，汇总展示全域各级各类国土空间规划编制项目信息与动态，提供项目信息填报、进度管理、成果管理等功能，支撑编制项目立项、招投标、合同签订、编制成果、征求意见、成果审查、成果报批、批后公示全过程标准化管理。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/eight.png')" />
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/nine.png')" />
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>监测评估预警
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;面向自然资源和规划业务管理部门，汇集监测国土空间三条控制线与规划指标运行数据，提供动态监测、及时预警、定期评估等功能，支持国土空间用途管制、开发利用与生态修复等业务应用，为指标动态调整、底线管控约束和开发政策制定提供依据。
        </div>
        <div style="display: flex; justify-content: space-between">
          <div class="tabimg">
            <img :src="require('@/assets/yskimg/solution/tabtwo/ten1.png')" />
          </div>
          <div class="tabimg" style="margin-left: 20px">
            <img :src="require('@/assets/yskimg/solution/tabtwo/ten2.png')" />
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          "
        >
          <div class="tabimg">
            <img :src="require('@/assets/yskimg/solution/tabtwo/ten3.png')" />
          </div>
          <div class="tabimg" style="margin-left: 20px">
            <img :src="require('@/assets/yskimg/solution/tabtwo/ten4.png')" />
          </div>
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>承载能力监测
        </div>
        <div class="content introduce fz18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;面向自然资源和规划业务管理部门，动态汇总监测各地各类资源开发利用指标数据，提供资源环境承载能力综合监管、动态评估、决策支持等功能，支撑资源环境承载能力分区分级评价与自然资源开发利用管理决策。
        </div>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/even1.png')" />
        </div>
        <p class="introduce fz18" style="text-align: center">
          资源承载能力监管
        </p>
        <div class="tabimg">
          <img :src="require('@/assets/yskimg/solution/tabtwo/even2.png')" />
        </div>
        <p class="introduce fz18" style="text-align: center">
          资源承载等级评估
        </p>
      </div>
      <p class="bottomdistance"></p>
    </div>
  </div>
</template>

<script>
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {
      data: [],
    };
  },
  created() {},
  mounted() {
    if (this.data.successcase) {
      this.getBanner();
    }
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container2', {
        direction: 'horizontal',
        // slidesPerView: 2,
        // spaceBetween: 30, //轮播图之间的间距
        loop: false,
        autoplay: true, //可选选项，自动滑动
        // navigation: {
        //   nextEl: '.swiper-button-next1',
        //   prevEl: '.swiper-button-prev1',
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tab-one {
  .tabimg {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  .topdistance {
    margin-top: 60px;
  }
  .bottomdistance {
    margin-bottom: 60px;
  }
  .introduce {
    line-height: 48px;
  }
  .solutionimg {
    margin: 60px 0;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          margin: 0 auto;
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
    // .swiper-button-prev,
    // .swiper-button-next {
    //   width: 30px;
    //   height: 60px;
    //   background: #c7c7c7;
    //   color: #fff;
    // }
  }
  .plus {
    .title {
      .sufixx {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        margin-right: 10px;
      }
    }
  }
}
</style>
