<template>
  <div class="tab-two">
    <div class="pic"></div>
    <div class="right-content">
      <div class="main-content">
        <div class="inner-title">
          <div class="title">
            <div>行业软件定制开发</div>
          </div>
          <div class="subtitle"></div>
        </div>
        <div class="introduct">
          <span class="single">“</span>
          公司与各省市自然资源主管部门、生态环境部门、水利部门、水务部门以及各大高校均有深度合作，凭借丰富的行业经验、先进适用的信息化产品和完善快捷的技术服务，立足于行业应用，依托业内领先、自主可控的GIS平台系列软件，打造空天地智能一体化数字孪生大数据平台，面向各领域提供GIS行业产品和解决方案。
          <span class="single">”</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/business.json';
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-two {
  padding-top: 60px;
  display: flex;
  width: 100%;

  .pic {
    width: 600px;
    height: 380px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background: url('../../assets/yskimg/business/area.png') no-repeat center;

    background-size: 100% 100%;
  }

  .right-content {
    flex: 1;
    position: relative;

    .main-content {
      width: 100%;
      background: #ffffff;
      height: 280px;
      position: absolute;
      left: -100px;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
    }
  }

  .inner-title {
    padding-left: 20px;
    position: relative;

    .title {
      font-size: 26px;
      color: #208ee1;
      line-height: 48px;
      > div {
        margin-left: 30px;
      }
    }

    .subtitle {
      position: absolute;
      height: 0px;
      width: 100px;
      border: 1px solid #208ee1;
    }
  }
  .introduct {
    padding: 15px;
    font-size: 18px;
    color: #000000;
    line-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    .content {
    }
  }
  .single {
    width: 21px;
    height: 18px;
    font-size: 60px;
    font-weight: 400;
    color: #208ee1;
    line-height: 30px;
  }
}
</style>
