import { render, staticRenderFns } from "./MainTwo.vue?vue&type=template&id=75079d82&scoped=true&"
import script from "./MainTwo.vue?vue&type=script&lang=js&"
export * from "./MainTwo.vue?vue&type=script&lang=js&"
import style0 from "./MainTwo.vue?vue&type=style&index=0&id=75079d82&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75079d82",
  null
  
)

export default component.exports