<template>
  <div class="main-tab">
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/1.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/2.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/3.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/4.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/5.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/6.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/7.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/8.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/9.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/10.png')" />
    </div>
    <div class="tabimg">
      <img :src="require('@/assets/yskimg/solution/tabeightpc/11.png')" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.main-tab {
  padding: 0 20px;
  width: 1100px;
  margin: 0 auto;
  padding-top: 60px;
  .introduct {
    padding: 10px 10px 0;
    line-height: 28px;
  }
  .tabimg {
    width: calc(100% - 20px);
    text-align: center;
    padding: 0 10px;
    img {
      max-width: 100%;
    }
  }
  .titleintroduce {
    padding: 10px 10px 0;
    .sufixx {
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #000;
      margin-right: 10px;
    }
  }
}
</style>
