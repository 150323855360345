<template>
  <div class="title-component-layout">
    <div class="title_topmain">
      <div class="title_left fz40">
        <i>{{ title }}</i>
      </div>
      <div class="title_center"></div>
      <div class="title_right fz26">{{ subtitle }}</div>
    </div>
    <div v-if="describe" style="margin-top: 35px; font-size: 14px">
      {{ describe }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'describe'],
  name: 'TitleComponentLayout',
};
</script>
<style lang="less" scoped>
.title-component-layout {
  .title_topmain {
    display: flex;
    line-height: 40px;

    .title_left {
      font-weight: 600;
    }

    .title_center {
      width: 80px;
      height: 2px;
      background: #222;
      margin: 19px 10px;
      line-height: 40px;
    }
  }
}
</style>
