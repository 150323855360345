<template>
  <div>
    <superslide
      :options="superSlideOption"
      v-if="superShow"
      class="picScroll-left"
    >
      <div class="hd">
        <ul>
          <li class="on"></li>
          <li class=""></li>
        </ul>
      </div>
      <div class="bd">
        <ul class="picList">
          <li v-for="item in newsArticle" :key="item.id">
            <div class="one_list">
              <img :src="item.cover" alt="" />
              <a :title="item.title" class="tit" @click="overallClick(item)">{{
                item.title
              }}</a>
              <div class="wrap_cont">
                <p>
                  {{ item.content | ellipsis(45) }}
                  【<a class="detail"   @click="overallClick(item)">详细信息</a>】
                </p>
              </div>
              <span>日期：{{item.publishTime | fmtDate_global}}</span>
            </div>
          </li>
        </ul>
      </div>
    </superslide>
  </div>
</template>

<script>
import { get } from "../../utils/request";
export default {
  data() {
    return {
      superShow: false,
      superSlideOption: {
        titCell: ".hd ul li",
        mainCell: ".bd ul",
        effect: "left",
        autoPlay: true,
        scroll: 4,
        vis: 4,
        trigger: "click",
      },
      newsArticle: [],
    };
  },
  created() {
    this.getSelectNews();
  },
  methods: {
    // 查询焦点咨讯栏目所有的文章前8个
    getSelectNews() {
      get("/index/article/pageQuery", { page: 1, pageSize: 8 ,categoryId:28}).then((res) => {
        if (res.status == 200) {
          this.newsArticle = res.data.list;
          this.superShow = false;
          this.$nextTick(() => {
            this.superShow = true;
          });
        }
      });
    },
    // 点击当前行
    overallClick(row) {
      this.$router.push({ 
        path: '/notice/details',
        query:{
          id:row.id,
        } 
      })
    },
  },
};
</script>
<style lang='less' scoped>
/* 本例子css */
.picScroll-left {
  width: 1200px;
  overflow: hidden;
  position: relative;
}
.picScroll-left .bd ul {
  // width: 2400px;
  overflow: hidden;
  zoom: 1;
}
.picScroll-left .bd ul li {
  margin-right: 5px;
  float: left;
}
.picScroll-left .bd ul li:nth-child(5) {
  margin-left: 0px;
}
.one_list {
  width: 260px;
  border: 1px solid #e6e4e0;
  padding: 34px 14px;
  background: #fff;
  height: 327px;
  img {
    width: 260px;
    height: 170px;
  }
  a.tit {
    font-size: 16px;
    font-weight: bold;
    color: #323232;
    line-height: 24px;
    padding: 14px 0 8px;
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  a.tit:hover {
    color: #680034;
    text-decoration: underline;
  }
  p {
    font-size: 14px;
    color: #787878;
    line-height: 24px;
    text-indent: 2em;
  }
  p a.detail {
    color: #faa209;
  }
  p a.detail:hover {
    color: #fdbf53;
  }
  span {
    color: #c9c9c9;
    text-align: right;
    display: block;
    line-height: 26px;
    padding: 12px 0 2px;
  }
}

.hd {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  z-index: 1;
  ul {
    overflow: hidden;
    zoom: 1;
    float: left;
    height: 12px;
    position: relative;
    width: 1180px;
    background: #e6e4df;
    border-radius: 25px;
    li {
      float: left;
      width: 50%;
      height: 12px;
      text-align: center;
      cursor: pointer;
      position: relative;
    }
    li.on {
      background: #afada9;
      border-radius: 25px;
    }
  }
}
</style>