var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PanelLayout',{attrs:{"bgcolor":_vm.bgcolor}},[_c('div',{class:_vm.panelthreeShow ? 'main_contentbg showdiv' : 'main_contentbg',attrs:{"id":"panelthree"}},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main_top"},[_c('TitleComponentLayout',{attrs:{"title":_vm.home_content1.titleEng1,"subtitle":_vm.home_content1.title,"describe":_vm.home_content1.titlemsg}}),_c('div',{staticClass:"main_top_right",on:{"click":_vm.handleMore}},[_c('i',{staticStyle:{"cursor":"pointer"}},[_vm._v("READ ALL +")])])],1),_c('div',{staticClass:"main_bottom"},[_c('div',{staticClass:"main_ul"},[_c('div',{staticClass:"main1"},[_c('el-carousel',{ref:"cardShow",staticStyle:{"width":"auto"},attrs:{"height":"500px"}},_vm._l((_vm.carousel),function(item,index){return _c('el-carousel-item',{key:index},[_c('router-link',{attrs:{"target":"_blank","to":{
                    path: '/newsdetail',
                    query: { id: item.uuid, title: item.title },
                  }}},[_c('div',{staticClass:"pic newspic",style:('background-image:url(' + item.image + ')')},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"titleinner"},[_vm._v(" "+_vm._s(item.title)+" ")])])])])],1)}),1)],1),_c('div',{staticClass:"rg"},[_c('div',{staticClass:"main2 flexcol"},_vm._l((_vm.leftnews),function(item,index){return _c('div',{key:index,staticClass:"new-item1"},[_c('div',{staticClass:"new-item1-inner"},[_c('div',{staticClass:"new-title"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v("       "+_vm._s(item.content)+" ")]),_c('div',{staticClass:"news-bottom"},[_c('div',{staticClass:"new-time"},[_c('div',{staticClass:"new-lf"},[_vm._v(_vm._s(item.daytime))]),_c('div',{staticClass:"new-rg"},[_vm._v(" "+_vm._s(item.yeartime)+"."+_vm._s(item.mothtime)+" ")])]),_c('router-link',{staticStyle:{"height":"100%"},attrs:{"target":"_blank","to":{
                        path: '/newsdetail',
                        query: { id: item.uuid, title: item.title },
                      }}},[_c('div',{staticClass:"detail-icon"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])])],1)])])}),0),_c('div',{staticClass:"main3 flexcol"},_vm._l((_vm.rightnews),function(item,index){return _c('div',{key:index,staticClass:"new-item2"},[_c('div',{staticClass:"new-item2-inner"},[_c('div',{staticClass:"lf"},[_c('div',{staticClass:"new-lf"},[_vm._v(_vm._s(item.daytime))]),_c('div',{staticClass:"new-rg"},[_vm._v(" "+_vm._s(item.yeartime)+"."+_vm._s(item.mothtime)+" ")])]),_c('div',{staticClass:"rg"},[_c('div',{staticClass:"new-title"},[_vm._v(_vm._s(item.title))]),_c('router-link',{attrs:{"target":"_blank","to":{
                        path: '/newsdetail',
                        query: { id: item.uuid, title: item.title },
                      }}},[_c('span',{staticClass:"btn-arrow"})])],1)])])}),0)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }