<template>
  <div class="tab-six">
    <div class="solution-panel">
      <div class="tabimg">
        <img
          :src="
            require('@/assets/yskimg/solution/tabsix/元时空地下管网综合管理系统_2.png')
          "
        />
      </div>
      <div class="tabimg">
        <img
          :src="
            require('@/assets/yskimg/solution/tabsix/元时空地下管网综合管理系统_3.png')
          "
        />
      </div>
      <div class="tabimg">
        <img
          :src="
            require('@/assets/yskimg/solution/tabsix/元时空地下管网综合管理系统_4.png')
          "
        />
      </div>
      <div class="tabimg">
        <img
          :src="
            require('@/assets/yskimg/solution/tabsix/元时空地下管网综合管理系统_5.png')
          "
        />
      </div>
      <div class="tabimg">
        <img
          :src="
            require('@/assets/yskimg/solution/tabsix/元时空地下管网综合管理系统_6.png')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-six {
  .tabimg {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  .topdistance {
    margin-top: 60px;
  }
  .bottomdistance {
    margin-bottom: 60px;
  }
  .introduce {
    line-height: 48px;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
  }
  .plus {
    .title {
      .sufixx {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        margin-right: 10px;
      }
    }
  }
  .title {
    .sufixx {
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #000;
      margin-right: 10px;
    }
  }
}
</style>
