<template>
  <div class="main-body">
    <PageTopImgLayout imgurl="assets/yskimg/news/topimg.png">
      <div class="bg-top-layout">
        <PanelLayout :bgcolor="'transpant'">
          <div class="title fz36">南京元时空资讯</div>
          <div class="subtitle fz22">
            我们秉持“关注客户、不断创新”的理念，竭力为客户提供热情贴心的售后服务
          </div>
        </PanelLayout>
      </div>
    </PageTopImgLayout>
    <PanelLayout>
      <TitleComponentLayout
        :title="Newsdata.titleEng1"
        :subtitle="Newsdata.title"
        :describe="Newsdata.titlemsg"
      >
      </TitleComponentLayout>
      <div class="content-body">
        <div
          :class="
            activeMainLiID === item.uuid ? 'news-item active' : 'news-item'
          "
          v-for="(item, index) in newsdatalist"
          :key="index"
          @mouseenter="handlemouseenter(item)"
          @mouseleave="handlemouseleave"
        >
          <router-link
            target="_blank"
            :to="{
              path: '/newsdetail',
              query: { id: item.uuid, title: item.title },
            }"
          >
            <div
              class="pic"
              :style="'background-image: url(' + item.image + ')'"
            ></div>
            <div class="detail">
              <div style="display: flex; align-items: center; margin-top: 20px">
                <div class="title">
                  {{ item.title }}
                </div>
                <span class="btn-arrow"></span>
              </div>
              <div class="topmessage">
                <div class="message">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="footer">
        <div class="showtotal">共{{ total }}条</div>
        <div class="pagnation">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </PanelLayout>
  </div>
</template>

<script>
import PanelLayout from '@/components/layout/PanelLayout';
import PageTopImgLayout from '@/components/layout/PageTopImgLayout';
import TitleComponentLayout from '@/components/layout/TitleComponentLayout';
import Resultjson from '../../../public/json/news.json';
import { get } from '@/utils/request.js';
import global from '../../../public/json/global.json';
export default {
  components: {
    PanelLayout,
    PageTopImgLayout,
    TitleComponentLayout,
  },
  data() {
    return {
      Newsdata: Resultjson.data,
      newsdatalist: [],
      total: 0,
      activeMainLiID: '',
      pageNum: 1,
      pageSize: 8,
    };
  },
  created() {
    this.getNewsList(1);
  },
  mounted() {},
  methods: {
    godetail(option) {
      this.$router.push({
        path: '/newsdetail',
        query: { id: JSON.stringify(option.uuid) },
      });
    },
    handlemouseenter(item) {
      this.activeMainLiID = item.uuid;
    },
    handlemouseleave() {
      this.activeMainLiID = '';
    },
    getNewsList(num) {
      this.pageNum = num;
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      get('/news/list', params).then((res) => {
        this.total = res.data.total;
        this.newsdatalist = res.data.rows;
        this.newsdatalist.map((item) => {
          if (item.coverImg.relativePath) {
            let tempPath = item.coverImg.relativePath;
            tempPath = tempPath.replace(/\\/g, '/');
            item.image = tempPath ? global.uploadUrl + tempPath : '';
          }

          if (item.content) {
            const re1 = new RegExp('<.+?>', 'g'); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
            const msg = item.content.replace(re1, ''); //执行替换成空字符
            item.content = msg;
          }
        });
      });
    },
    handleCurrentChange(pageindex) {
      this.getNewsList(pageindex);
    },
  },
};
</script>
<style lang="less" scoped>
.main-body {
  padding-top: 100px;

  .bg-top-layout {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-style: italic;

    .title {
      padding-bottom: 15px;
    }
  }

  .content-body {
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .news-item {
      padding: 10px;
      cursor: pointer;

      &.active {
        .pic {
          transform: scale(1.1);
        }

        .btn-arrow {
          display: inline-block;
          width: 25px;
          height: 8px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
          position: relative;
          margin: 0 5px;
          background-image: url(../../assets/yskimg/home/title-arrow.png);
        }

        .detail {
          padding-bottom: 20px;

          .topmessage {
            border-bottom: 1px solid #000;
          }

          .message {
            margin-bottom: 40px;
            border: none;
            text-overflow: clip;
          }
        }
      }

      .pic {
        width: 360px;
        height: 274px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: all 0.6s ease-out 0s;
        // background-image: url('../../assets/yskimg/home/banner.png');
      }

      .detail {
        background-color: #fff;
        width: 360px;
        padding: 20px 0 40px 0;

        .title {
          position: relative;
          font-size: 18px;
          // margin: 20px 0 0 0;
          width: calc(100% - 40px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .btn-arrow {
            position: absolute;
            right: 0;
            top: 10px;
          }
        }
        .topmessage {
          border-bottom: 1px solid #e6e6e6;
        }
        .message {
          margin: 20px 0 20px 0;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          position: relative;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .showtotal {
    }
    .pagnation {
    }
  }
}
</style>
