<template>
  <div class="out_Container">
    <div class="n_left">
      <h2>学院简介</h2>
      <ul class="n_nav">
        <li><a href="#" @click.prevent="toPage.prevent('/introduction')" class="cur">学院概况</a></li>
        <li><a href="#" @click.prevent="toPage.prevent('/introduction/leader')">学院领导</a></li>
        <li><a href="#" @click.prevent="toPage.prevent('/introduction/history')">历史沿革</a></li>
      </ul>
    </div>
    <div class="n_right">
      <div class="n_tit">
        <span class="title">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/introduction' }"
            >学院简介</el-breadcrumb-item
          >
          <el-breadcrumb-item>学院概况</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1 class="Title TitleLong">
        <span class="tit">学院概况</span>
        <span class="line">
          <b class="line_l"></b>
          <b class="line_s"></b>
        </span>
      </h1>
      <div class="content" v-html="articleData.content">
        {{ articleData.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // article:[]
    };
  },
  created() {
    var params = {
      page: 1,
      pageSize: 1,
      categoryId: 32,
    };
    this.getArticleData(params);
  },
  computed: {
    // 引入用户信息对象
    ...mapState("article", ["articleData"]),
  },
  methods: {
    // 引入用户数据
    ...mapActions("article", ["getArticleData"]),
    // 跳转路由
    toPage(path) {
      this.$router.push(path);
    },
  },
};
</script>
