<template>
  <div>
    <div class="top">
      <div class="main">
        <div class="box" v-for="item in videoArticle" :key="item.id" @click.prevent="overallClick(item)">
          <a href="#">
            <img :src="item.cover" :title="item.title" />
            <span class="bfbtn"
              ><img src="../../assets/yxtx/bofang.png"
            /></span>
          </a>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="main">
        <superslide
          class="picScroll-top"
          :options="superSlideOption"
          v-if="superShow"
        >
          <div class="hd">
            <div class="zhut">
              <a href="#"
                ><span>学子<br />风采</span></a
              >
            </div>
            <a class="next"></a>
            <a class="prev"></a>
          </div>
          <div class="bd">
            <div class="tempWrap">
              <ul class="picList">
                <li v-for="item in studentArticle" :key="item.id" @click.prevent="overallClick(item)">
                  <a href="#">
                    <img :src="item.cover" :title="item.title" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </superslide>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "../../utils/request";
export default {
  data() {
    return {
      videoArticle: [],
      studentArticle: [],
      superShow: false,
      superSlideOption: {
        titCell: ".hd ul li",
        mainCell: ".bd ul",
        effect: "leftLoop",
        autoPlay: true,
        vis:5,
        trigger: "click",
      },
    };
  },

  created() {
    this.getVideoData();
    this.getStudentArticle();
  },

  methods: {
    // 查询视频专区前5个
    getVideoData() {
      get("/index/article/pageQuery", {
        page: 1,
        pageSize: 5,
        categoryId: 31,
      }).then((res) => {
        if (res.status == 200) {
          this.videoArticle = res.data.list;
        }
      });
    },
    // 查询学子风采
    getStudentArticle() {
      get("/index/article/pageQuery", {
        page: 1,
        pageSize: 20,
        categoryId: 29,
      }).then((res) => {
        if (res.status == 200) {
          this.studentArticle = res.data.list;
          this.superShow = false;
          this.$nextTick(() => {
            this.superShow = true;
          });
        }
      });
    },
    // 点击当前行
    overallClick(row) {
      this.$router.push({ 
        path: '/notice/details',
        query:{
          id:row.id,
        } 
      })
    },
  },
};
</script>
<style lang='less' scoped>
.main {
  width: 1200px;
  margin: 0 auto;
  height: auto;
}
.top {
  margin-top: 25px;
  .main {
    height: 140px;
    .box {
      margin-right: 20px;
      float: left;
      width: 220px;
      height: 140px;
      a > img {
        width: 220px;
        height: 140px;
      }
      .bfbtn {
        position: relative;
        left: 80px;
        z-index: 100000;
        top: -100px;
        opacity: 0.8;
      }
    }
  }
}
.bottom {
  margin-top: 30px;
  .main {
    .picScroll-top {
      width: 100%;
      overflow: hidden;
      position: relative;
      .hd {
        .zhut {
          position: absolute;
          top: 0px;
          width: 217px;
          height: 170px;
          left: 0px;
          background: url(../../assets/yxtx/ico6.png) no-repeat;
          span {
            border: 2px solid #fff;
            display: inline-block;
            width: 118px;
            height: 122px;
            text-align: center;
            font-size: 30px;
            color: #ffffff;
            margin-top: 22px;
            margin-left: 21px;
          }
        }
        .prev,
        .next {
          display: block;
          width: 30px;
          height: 30px;
          overflow: hidden;
          top: 50%;
          margin-top: -15px;
          position: absolute;
          cursor: pointer;
          background: url(../../assets/yxtx/btn3.png) no-repeat;
        }
        .prev {
          left: 183px;
          background-position: 0 0;
        }
        .next {
          right: 20px;
          z-index: 999;
          background-position: right 0;
        }
      }
      .bd {
        padding: 18px 0 18px 230px;
        background: #5e5e5e;
        height: 132px;
        .tempWrap {
          overflow: hidden;
          position: relative;
          width: 905px;
          ul {
            overflow: hidden;
            zoom: 1;
            li {
              margin: 0 14px;
              float: left;
              overflow: hidden;
              text-align: center;
              img {
                width: 148px;
                height: 128px;
                display: block;
                border: 2px solid #fff;
              }
              img:hover {
                border: 2px solid #680034;
              }
            }
          }
        }
      }
    }
  }
}
</style>