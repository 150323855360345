<template>
  <div class="tab-one">
    <div class="solution-panel">
      <SolutionTitleLayout
        title="概述"
        subtitle="Overview"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p
        class="introduce fz18"
        v-for="(item, index) in data.bgintroduce"
        :key="'tabtfourbg' + index"
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item }}
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/古建筑三维1.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="技术优势"
        subtitle="Technical Advantages"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传统的古建测绘⽅法获得的是“图样”，航摄测量的最终成果是测绘建筑物平面图、截面图、立面图等。这两种测绘技术获得的均是反映建筑物信息的⼆维平⾯图纸，而三维激光扫描技术获得的数据则是建筑物的实景三维模型，模型中包含了建筑所有结构、细部乃⾄材质信息。元时空利用三维激光扫描技术能够在几个小时内获得传统测绘⽅式数天或者更长时间都不能获得的古建信息，相比传统测绘方法，节省了时间、人力，工作效率⼤幅提升。同时，三维激光扫描技术有很强的适用性，在很多复杂的环境中都可以进⾏测量。此外，三维激光扫描设备通过发射激光束来实现实景三维坐标的精确获取，可不依赖辅助光源，实现全天候作业。
      </p>
      <div class="tabimg">
        <img :src="require('@/assets/yskimg/solution/古建筑三维2.png')" />
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="基础数字化采集"
        subtitle="Basic Digital Acquisition"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>古建筑院落整体数字化采集
        </div>
        <div class="content introduce fz18">
          对古建筑内彩绘、彩绘墙以及建筑进行基础采集，是进行文物保护、研究、展示的基础，将主要运用三维扫描、航摄测量等技术手段展开对文物现状采集工作，重点关注斗拱、脊兽、石碑等。
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>木牌楼高精度采集
        </div>
        <div class="content introduce fz18">
          使⽤地⾯三维扫描设备进⾏古建筑整体建筑三维扫描，重点关注⽊牌楼及其他相关建筑⽂物。三维点云点间距不⼤于2cm，点位精度优于2cm。
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>彩绘数字化采集
        </div>
        <div class="content introduce fz18">
          采⽤三维扫描获取的数据包括古建筑整体院落三维信息、建筑内外三维信息、殿内彩绘的空间三维信息。采用航摄测量技术获取殿内彩绘、彩绘墙三维信息和色彩纹理信息，共同搭建起古建筑的整体实景三维建筑空间的数字化模型和信息数据库。
        </div>
        <div class="content introduce fz18">
          使用正直多基线高清晰数字摄影法，在泛光标准光源环境下，真实客观记录彩绘现状的色彩纹理信息和水渍、颜料层脱落、褪色等表面病害；将航摄采集数据与三维扫描⼏何形体数据精确匹配，形成可量测的实景三维彩绘和高清正摄影像图，并可对彩绘表面病害进行精确定位。
        </div>
      </div>
      <p class="bottomdistance"></p>
      <SolutionTitleLayout
        title="古建筑三维模型构建"
        subtitle="3D Model Construction"
      ></SolutionTitleLayout>
      <p class="topdistance"></p>
      <div class="title introduce fz22"><span class=""></span>数据信息准备</div>
      <p class="introduce fz18">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过三维激光扫描技术采集古建筑模型信息，得到模型点云数据。点云数据精度⾼，采集速度快，是古建筑数据采集常用的方法。辅助拍照、手绘、资料查询等方法，为构建“族”模型的建立准备了丰富的基础数据。
      </p>
      <div style="text-align: center">
        <img
          class="overimg"
          :src="require('@/assets/yskimg/solution/古建筑三维3.png')"
        />
      </div>
      <div class="title introduce fz22">
        <span class=""></span>三维模型的构建
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>构建的分类
        </div>
        <div class="content introduce fz18">
          根据建筑类型的理论，以建筑构造特征为基础，尝试为单体化的建筑的构件进行分类，并按照古建筑文保的需求设置构件属性。
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>模型的搭建
        </div>
        <div class="content introduce fz18">
          在轴网中对每个构件插入位置进行定位后，从下至上逐个载入族类型，并进行构件实例属性的修改和添加。
        </div>
      </div>
      <div class="plus">
        <div class="title introduce fz18">
          <span class="sufixx"></span>古建筑实景三维可视化
        </div>
        <div class="content introduce fz18">
          为保证古建筑相关数据的展示利用，制定古建筑三维互动呈现系统，利用技术手段实现古建筑的实景三维互动，以古建筑情景的模型建立、美化渲染为基础，将古建筑的数字化结果利用起来。
        </div>
      </div>
      <p class="bottomdistance"></p>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import SolutionTitleLayout from '@/components/layout/SolutionTitleLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  components: {
    SolutionTitleLayout,
  },
  data() {
    return {
      data: Resultjson.data.TabData[3],
    };
  },
  created() {},
  mounted() {
    if (this.data.successcase) {
      this.getBanner();
    }
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container4', {
        direction: 'horizontal',
        // slidesPerView: 2,
        // spaceBetween: 30, //轮播图之间的间距
        loop: false,
        autoplay: true, //可选选项，自动滑动
        // navigation: {
        //   nextEl: '.swiper-button-next1',
        //   prevEl: '.swiper-button-prev1',
        // },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tab-one {
  .tabimg {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .solution-panel {
    padding-top: 60px;
    width: 1100px;
    margin: 0 auto;
  }
  .topdistance {
    margin-top: 60px;
  }
  .bottomdistance {
    margin-bottom: 60px;
  }
  .introduce {
    line-height: 48px;
  }
  .solutionimg {
    margin: 60px 0;
  }
  .overimg {
    max-width: 100%;
    margin: 0 auto;
  }
  .pic {
    margin: 60px 0;
    width: 100%;
    height: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background-image: url('../../assets/yskimg/home/banner.png');
  }
  .swiper-father {
    margin-top: 60px;
    // width: 100%;
    // height: 50vh;
    // min-height: 300px;
    position: relative;
    .swiper-container {
      // width: calc(100% - 100px);
      // height: 50vh;
      // min-height: 300px;
      .swiper-slide {
        margin-right: 30px;
        .case-pic {
          margin: 0 auto;
          width: 501px;
          height: 317px;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
        }
        .title {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
    // .swiper-button-prev,
    // .swiper-button-next {
    //   width: 30px;
    //   height: 60px;
    //   background: #c7c7c7;
    //   color: #fff;
    // }
  }
  .plus {
    .title {
      .sufixx {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        margin-right: 10px;
      }
    }
  }
}
</style>
