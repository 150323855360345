<template>
  <div class="out_Container">Peiyang</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  created(){},

  methods: {}
}

</script>
<style lang='less' scoped>
</style>