<template>
  <div class="out_Container">
    <div class="n_left">
      <h2>师资队伍</h2>
      <ul class="n_nav">
        <li><a href="#" @click.prevent="toPage('/teacher')">教师团队</a></li>
        <li><a href="#" @click.prevent="toPage('/teacher/guwen')" >顾问委员会</a></li>
        <li><a href="#" @click.prevent="toPage('/teacher/fazhan')" >发展委员会</a></li>
        <li><a href="#" @click.prevent="toPage('/teacher/xueshu')"  class="cur">学术委员会</a></li>
        <li><a href="#" @click.prevent="toPage('/teacher/zhuanye')">专业导师</a></li>
        <li><a href="#" @click.prevent="toPage('/teacher/chuangye')">创业导师</a></li>
        <li><a href="#" @click.prevent="toPage('/teacher/teyao')">特邀讲师</a></li>
      </ul>
    </div>
    <div class="n_right">
      <div class="n_tit">
        <span class="title">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/teacher' }">师资队伍</el-breadcrumb-item>
          <el-breadcrumb-item>学术委员会</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1 class="Title TitleLong">
        <span class="tit">学术委员会</span>
        <span class="line">
          <b class="line_l"></b>
          <b class="line_s"></b>
        </span>
      </h1>
      <div class="content" v-html="articleData.content">
        {{ articleData.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
    };
  },
  created() {
    var params = {
      page: 1,
      pageSize: 1,
      categoryId: 19,
    };
    this.getArticleData(params);
  },
  computed: {
    // 引入用户信息对象
    ...mapState("article", ["articleData"]),
  },
  methods: {
    // 引入用户数据
    ...mapActions("article", ["getArticleData"]),
    // 跳转路由
    toPage(path) {
      this.$router.push(path);
    },
    
  },
};
</script>
<style>
.n_left .n_nav li a {
  width: 120px;
}
.content p{
  margin-bottom: 20px;
}
</style>
