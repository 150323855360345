<template>
  <div class="out_Container">
    <div class="n_left">
      <h2>教学科研</h2>
      <ul class="n_nav">
        <li><a href="#" @click.prevent="toPage('/search')">科研平台</a></li>
        <li><a href="#" @click.prevent="toPage('/search/res')">科研成果</a></li>
        <li><a href="#" @click.prevent="toPage('/search/project')">科研项目</a></li>
        <li><a href="#" @click.prevent="toPage('/search/jiaoliu')"  class="cur">学术交流</a></li>
      </ul>
    </div>
    <div class="n_right">
      <div class="n_tit">
        <span class="title">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/search' }">教学科研</el-breadcrumb-item>
          <el-breadcrumb-item>学术交流</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1 class="Title TitleLong">
        <span class="tit">学术交流</span>
        <span class="line">
          <b class="line_l"></b>
          <b class="line_s"></b>
        </span>
      </h1>
      <!-- 列表 -->
      <ul class="list">
        <li v-for="item in allArticleData.list" :key="item.id" @click="overallClick(item)">
          <a href="#">
            <span>{{ item.publishTime | fmtDate_global }}</span>
            {{ item.title }}
          </a>
        </li>
      </ul>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :total="allArticleData.total"
          :page-size="obj.pageSize"
          :page-sizes="[3, 5, 10]"
          :current-page="obj.page"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      obj: {
        page: 1,
        pageSize: 5,
        categoryId: 39,
      },
    };
  },
  watch: {
    obj: {
      handler: function () {
        this.getAllArticleData(this.obj);
      },
      deep: true,
    },
  },
  created() {
    this.getAllArticleData(this.obj);
  },
  computed: {
    // 引入用户信息对象
    ...mapState("allArticle", ["allArticleData"]),
  },
  methods: {
    // 引入用户数据
    ...mapActions("allArticle", ["getAllArticleData"]),
    // 跳转路由
    toPage(path) {
      this.$router.push(path);
    },
    handleCurrentChange(val) {
      this.obj.page = val;
    },
    handleSizeChange(val){
      this.obj.pageSize = val;
    },
    // 点击当前行
    overallClick(row) {
      this.$router.push({ 
        path: '/notice/details',
        query:{
          id:row.id,
        } 
      })
    },
  },
};
</script>