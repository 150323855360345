<template>
  <div class="title-component-layout">
    <div class="title_topmain fz72">
      {{ title }}
    </div>
    <div class="title_center">
      <span class="fz36">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle'],
  name: 'EnterusTitleLayout',
};
</script>
<style lang="less" scoped>
.title-component-layout {
  position: relative;
  .title_topmain {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Proza Libre;
    font-weight: 400;
    font-style: italic;
    color: #000000;
    line-height: 22px;
    opacity: 0.1;
    -webkit-text-stroke: 1px;
  }
  .title_center {
    margin-top: 15px;
    text-align: center;
    span {
      position: relative;
      font-weight: bold;
      color: #010101;
      line-height: 22px;
      text-align: center;
    }
    span:before {
      position: absolute;
      bottom: -10px;
      left: 0;
      content: '';
      width: 90px;
      border-top: 3px solid #208ee1;
    }
    span:after {
      position: absolute;
      bottom: -10px;
      right: 0;
      content: '';
      width: 40px;
      border-top: 3px solid #208ee1;
    }
  }
}
</style>
