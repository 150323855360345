<template>
  <PanelLayout>
    <div class="main_contentbg" id="paneltwo">
      <div class="main">
        <div class="main_top">
          <TitleComponentLayout
            :title="home_content2.titleEng1"
            :subtitle="home_content2.title"
            :describe="home_content2.titlemsg"
          ></TitleComponentLayout>
          <div class="main_top_right" @click="handleMore(1)">
            <i style="cursor: pointer">READ ALL +</i>
          </div>
        </div>
        <div class="main_bottom">
          <div class="swiper-father">
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in dataList"
                  :key="index"
                >
                  <div
                    class="pic"
                    :style="
                      'background-image: url(' + require('@/' + item.url) + ')'
                    "
                  ></div>
                  <div class="pic-detail">
                    <div class="title">{{ item.name }}</div>
                    <div class="detail-btn" @click="handleMore(item.id)">
                      <span>了解详情</span><span class="btn-arrow"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 我们把swiper 的箭头放到swiper 外面 -->
            <div class="swiper-button-prev swiper-button-prev1"></div>
            <div class="swiper-button-next swiper-button-next1"></div>
          </div>
        </div>
      </div>
    </div>
  </PanelLayout>
</template>

<script>
import Home_content from '../../../public/Home_content';
import PanelLayout from '@/components/layout/PanelLayout';
import TitleComponentLayout from '@/components/layout/TitleComponentLayout';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { get } from '../../utils/request';
import Resultjson from '../../../public/json/solution.json';
export default {
  components: {
    PanelLayout,
    TitleComponentLayout,
  },
  data() {
    return {
      home_content2: Home_content.home_content2,
      // dataList: Home_content.home_content2.main,
      dataList: Resultjson.data.TabData,
    };
  },

  created() {},
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      var mySwiper = new Swiper('.swiper-container1', {
        direction: 'horizontal',
        // slidesPerView: 4,
        slidesPerView: 'auto',
        spaceBetween: 50, //轮播图之间的间距
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1',
        },
      });
    },
    handleMore(id) {
      this.$router.push({
        path: '/solution',
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main_contentbg {
  background: #fff;
  // height: 900px;
  // max-width: 2560px;
  margin: 0 auto;
  color: #000;

  .main {
    overflow: hidden;
    // width: 1600px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;

    .main_top {
      display: flex;
      justify-content: space-between;

      .title_topmain {
        display: flex;
        line-height: 40px;

        .title_left {
          font-size: 40px;
          font-weight: 600;
        }

        .title_center {
          width: 80px;
          height: 2px;
          background: #222;
          margin: 19px 10px;
          line-height: 40px;
        }

        .title_right {
          font-size: 26px;
        }
      }

      .main_top_right {
        margin-top: 10px;
      }
    }

    .main_bottom {
      width: 100%;
      padding-top: 60px;
      .swiper-father {
        width: 100%;
        height: 505px;
        min-height: 350px;
        position: relative;
        .swiper-container {
          width: calc(100% - 100px);
          height: 505px;
          min-height: 350px;
          padding: 0 10px;
          .swiper-slide {
            width: 324px;
            height: 500px;
            .pic {
              width: 100%;
              height: 364px;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              transition: all 0.6s ease-out 0s;
              position: relative;
              // margin: 0 30px;
              box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
            }
            .pic-detail {
              // position: absolute;
              // bottom: 0;
              // margin: 0 30px;
              height: 136px;
              background-color: rgba(255, 255, 255, 0.9);
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
              .title {
                font-size: 18px;
                margin-bottom: 20px;
              }
              .detail-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #969595;
                cursor: pointer;
                width: 135px;
                height: 28px;
                background: #ffffff;
                border: 1px solid #a6a6a6;
                border-radius: 14px;
                span {
                  display: inline-block;
                }
                .btn-arrow {
                  display: inline-block;
                  width: 25px;
                  height: 8px;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  transition: all 0.6s ease-out 0s;
                  position: relative;
                  margin: 0 5px;
                  background-image: url(../../assets/yskimg/home/title-arrow.png);
                }
              }
            }
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          width: 30px;
          height: 60px;
          background: #c7c7c7;
          color: #fff;
        }
      }
    }
  }
}
</style>
