<template>
  <div class="main-body">
    <PageTopImgLayout imgurl="assets/yskimg/solution/topimg.png">
      <div class="bg-top-layout">
        <PanelLayout :bgcolor="'transpant'">
          <div class="title fz36">解决方案</div>
          <div class="subtitle fz22">
            我们秉持“关注客户、不断创新”的理念，竭力为客户提供热情贴心的售后服务
          </div>
        </PanelLayout>
      </div>
    </PageTopImgLayout>
    <PanelLayout>
      <TitleComponentLayout
        :title="Resultdata.titleEng1"
        :subtitle="Resultdata.title"
        :describe="Resultdata.titlemsg"
      ></TitleComponentLayout>
      <div class="content-body">
        <div class="tab-header">
          <span
            :class="activeMainLiID === item.id ? 'active' : ''"
            v-for="(item, index) in TabData"
            :key="index"
            @click="handleTabClick(item)"
            >{{ item.name }}</span
          >
        </div>
        <div class="tab-content">
          <Tabone v-show="activeMainLiID === 1"></Tabone>
          <Tabtwo v-show="activeMainLiID === 2"></Tabtwo>
          <Tabthree v-show="activeMainLiID === 3"></Tabthree>
          <Tabfour v-show="activeMainLiID === 4"></Tabfour>
          <Tabfive v-show="activeMainLiID === 5"></Tabfive>
          <Tabsix v-show="activeMainLiID === 6"></Tabsix>
          <Tabseven v-show="activeMainLiID === 7"></Tabseven>
          <Tabeight v-show="activeMainLiID === 8"></Tabeight>
        </div>
      </div>
    </PanelLayout>
  </div>
</template>

<script>
import PanelLayout from '@/components/layout/PanelLayout';
import PageTopImgLayout from '@/components/layout/PageTopImgLayout';
import TitleComponentLayout from '@/components/layout/TitleComponentLayout';
import Tabone from './tabone.vue';
import Tabtwo from './tabtwo.vue';
import Tabthree from './tabthree.vue';
import Tabfour from './tabfour.vue';
import Tabfive from './tabfive.vue';
import Tabsix from './tabsix.vue';
import Tabseven from './tabseven.vue';
import Tabeight from './tabeightpc.vue';
import Resultjson from '../../../public/json/solution.json';
export default {
  components: {
    PanelLayout,
    PageTopImgLayout,
    TitleComponentLayout,
    Tabone,
    Tabtwo,
    Tabthree,
    Tabfour,
    Tabfive,
    Tabsix,
    Tabseven,
    Tabeight,
  },
  data() {
    return {
      Resultdata: Resultjson.data,
      activeMainLiID: 5,
      TabData: Resultjson.data.TabData,
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.activeMainLiID = parseFloat(this.$route.query.id);
    }
  },
  mounted() {},
  methods: {
    handleTabClick(item) {
      this.activeMainLiID = item.id;
    },
  },
};
</script>
<style lang="less" scoped>
.main-body {
  padding-top: 100px;
  .bg-top-layout {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-style: italic;
    .title {
      padding-bottom: 15px;
      position: relative;
    }
    .title:after {
      position: absolute;
      content: '';
      left: 0;
      border: 1px solid #fff;
      bottom: 0;
      width: 30px;
    }
    .subtitle {
      padding-top: 15px;
    }
  }
  .content-body {
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .tab-header {
      width: 100%;
      height: 60px;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        height: 25px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 2px;
        padding: 5px 10px;
        width: 100px;
        text-align: center;
        &.active {
          background: #208ee1;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    width: 100%;
  }
}
</style>
