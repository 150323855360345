import { render, staticRenderFns } from "./lianxi.vue?vue&type=template&id=95873548&"
import script from "./lianxi.vue?vue&type=script&lang=js&"
export * from "./lianxi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports