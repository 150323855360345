<template>
  <div class="tab-four">
    <div class="enterus-panel">
      <EnterusTitleLayout
        title="ELEGANT APPEARANCE"
        subtitle="元时空风采"
      ></EnterusTitleLayout>

      <PanelLayout>
        <p class="introduce fz16">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ introduce1 }}
        </p>
        <p class="introduce fz16">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ introduce2 }}
        </p>
        <div class="team-layout">
          <div class="item">
            <div class="cell">
              <span class="fz30">团队风采</span>
              <span class="fz16">Team spirit</span>
            </div>
            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/团队风采.png')" />
            </div>
            <div class="cell">
              <span class="fz30">文化活动</span>
              <span class="fz16">Cultural activity</span>
            </div>
            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/文化活动.png')" />
            </div>
          </div>
          <div class="item">
            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/团建.png')" />
            </div>
            <div class="cell">
              <span class="fz30">团建</span>
              <span class="fz16">League building</span>
            </div>

            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/元时空环境.png')" />
            </div>
            <div class="cell">
              <span class="fz30">元时空工会</span>
              <span class="fz16">Meta time union</span>
            </div>
          </div>
          <div class="item">
            <div class="cell">
              <span class="fz30">团队风采</span>
              <span class="fz16">Team spirit</span>
            </div>
            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/团队风采3.png')" />
            </div>
            <div class="cell">
              <span class="fz30">团队风采</span>
              <span class="fz16">Team spirit</span>
            </div>
            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/真人cs.png')" />
            </div>
          </div>
          <div class="item">
            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/团队风采2.png')" />
            </div>
            <div class="cell">
              <span class="fz30">团队风采</span>
              <span class="fz16">Team spirit</span>
            </div>

            <div class="pic">
              <img :src="require('@/assets/yskimg/enterus/团队风采1.png')" />
            </div>
            <div class="cell">
              <span class="fz30">团队风采</span>
              <span class="fz16">Team spirit</span>
            </div>
          </div>
        </div>
      </PanelLayout>
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/solution.json';
import PanelLayout from '@/components/layout/PanelLayout';
import EnterusTitleLayout from '@/components/layout/EnterusTitleLayout';
export default {
  components: {
    EnterusTitleLayout,
    PanelLayout,
  },
  data() {
    return {
      activeID: 1,
      introduce1:
        '南京元时空地理信息技术有限公司成立于2021年12月31日，座落于江苏省南京市栖霞区马群街道紫东路1号紫东国际创意园，是一家专注于GIS信息化平台建设的国家高新技术、双软企业，元时空以“专注GIS信息化建设，促进地理信息社会化应用”为目标，坚持自主创新，立足于行业应用，面向地质、水利、国土、测绘、生态环境、自然资源等领域提供GIS行业产品和解决方案，深入挖掘测绘地理信息数据价值，激发地理信息产业活力。',
      introduce2:
        '公司于2022年被评估为双软企业并通过ISO9001：2008质量认证，2022年取得了国家高新技术企业认证和除了大地测量以外全项乙级测绘资质。自成立以来与江苏省测绘地理信息局、江苏省地质调查研究院、南京市规划局、水利部南京水利水文自动化研究所、武大吉奥信息技术有限公司、广州城市信息研究所有限公司、广州都市圈网络科技有限公司、北京睿城传奇科技有限公司等单位均有深度合作，与中国科学院古生物研究所、南京师范大学、南京农业大学开展产学研合作，积极推动拥有自主知识产权的地理信息产业的发展，现已拥有实用新型专利四项、城市地下管网、建设用地批后监管、测绘成果档案管理等地理信息相关行业应用软件产品的著作权十三项。凭借丰富的行业经验、先进实用的信息化产品和完善快捷的技术服务，实现了元时空GIS系列产品在湖北、江苏、辽宁、浙江、云南等地区相关行业的广泛应用，受到了用户的一致好评和推荐。',
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleHeaderClick(id) {
      this.activeID = id;
    },
  },
};
</script>
<style lang="less" scoped>
.tab-four {
  .enterus-panel {
    padding-top: 60px;
    width: 100%;
  }
  .introduce {
    width: 1100px;
    margin: 0 auto;
    line-height: 35px;
  }
  .team-layout {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      margin-bottom: 10px;
      .cell {
        width: 24%;
        background: #3f3f3f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .pic {
        width: 26%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
