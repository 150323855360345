<template>
  <div class="footer">
    <div class="footone">
      <ul class="fl">
        <li>
          <a href="https://www.gxust.edu.cn/">广西科技大学</a>
          <a href="http://www.tusdt.com/">启迪数学学院</a>
          <a href="http://172.16.129.117/">教学教务系统</a>
        </li>
        <li>
          <a href="http://www.gxust.edu.cn/tsg/">图书馆</a>
          <a href="http://tusdt.xuetangx.com/about">慕课平台</a>
          <a href="http://kjt.gxzf.gov.cn/">广西科技信息网</a>
        </li>
        <li>
          <a href="http://www.cnipa.gov.cn/">国家知识产权局</a>
          <a href="https://isisn.nsfc.gov.cn/egrantweb/"
            >国家自认科学基金委员会</a
          >
          <a href="http://kjj.liuzhou.gov.cn/">柳州市科技信息网</a>
        </li>
        <li>
          <a href="http://jyt.gxzf.gov.cn/">广西壮族自治区教育厅</a>
          <a href="http://program.most.gov.cn/">国家科技计划项目申报中心</a>
          <a href="http://gxnsf.gxsti.net/stms/login.jsp"
            >广西自然科学基金项目管理信息系统</a
          >
        </li>
      </ul>
      <div class="ftonr_r fl">
        <h2>联系我们</h2>
        <p>邮编：545006</p>
        <p>电话：0772-2686131</p>
      </div>
      <div class="ftonr_r fl">
        <img src="../../assets/yxtx/code.jpg" alt="" />
      </div>
    </div>
    <div class="footbottom">
      <div class="foot">
        <p>
          @ 2009 CopyRight 广西科技大学
          启迪数字学院版权所有&nbsp;&nbsp;地址：柳州市东环路268号广西科技大学科教大楼11楼
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
<style lang='less' scoped>
.footer {
  clear: both;
  width: 100%;
  background: #680034;
  margin-top: 40px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  .fl {
    float: left;
  }
  .footone {
    width: 1190px;
    padding: 0 5px;
    margin: 0 auto;
    height: 158px;
    ul li {
      float: left;
      margin-top: 36px;
      margin-right: 20px;
      padding-left: 20px;
      border-left: 1px solid #fff;
      height: 105px;
      a {
        display: block;
        color: #fff;
        font-size: 14px;
        line-height: 26px;
        padding-bottom: 12px;
      }
      a:hover {
        color: #ffcf46;
      }
    }
    .ftonr_r {
      font-size: 14px;
      color: #fff;
      margin-left: 19px;
      padding-bottom: 10px;
      h2 {
        font-size: 24px;
        font-weight: normal;
        padding-top: 20px;
        margin: 0px;
      }
      p {
        margin: 10px 0px;
      }
      img {
        width: 120px;
        height: 120px;
        margin-top: 25px;
      }
    }
  }
  .footbottom {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #282a2e;
    color: #ababac;
    font-size: 14px;
    .foot{
      width: 1190px;
      margin: 0 auto;
      height: auto;
      overflow: hidden;
    }
  }

}
</style>