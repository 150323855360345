module.exports = {
  //首页轮播数据
  bannersimg: [
    { url: 'assets/yskimg/home/banner.png' },
    { url: 'assets/yskimg/home/banner-jcch.jpg' },
    { url: 'assets/yskimg/home/banner-zhps.jpg' },
    { url: 'assets/yskimg/home/banner-zrzy.png' },
  ],
  //首页内容区域数据
  home_content1: {
    titleEng1: 'BUSINESS AREA',
    title: '业务领域',
    titlemsg: '',
    main: [
      {
        id: 1,
        title: '测绘与地理信息系统工程',
        content:
          '公司以政府部门及各类企事业单位为服务对象，为城市规划、自然资源管理、生态与环境管理、水利、水务管理等提供测绘技术、咨询服务、地理信息建库及信息化系统建设服务。业务范围包括：不动产测绘（房产测量）、管线探测与检测、地形测绘、地籍测量、控制测量、航空摄影测量、遥感影像处理、GIS 数据加工、地图编制、测绘监理、地理信息数据建库、GIS数据库管理系统、专业GIS系统开发等。',
        iconurl: 'assets/yskimg/home/chydlxx.png',
        activeiconurl: 'assets/yskimg/home/chydlxx-active.png',
        newiconurl:
          'https://pic4.zhimg.com/v2-c267bff87dc69b67436546d2c4b4ccdb_r.jpg',
      },
      {
        id: 2,
        title: '行业软件定制开发',
        content:
          '公司与各省市自然资源主管部门、生态环境部门、水利部门、水务部门以及各大高校均有深度合作，凭借丰富的行业经验、先进适用的信息化产品和完善快捷的技术服务，立足于行业应用，依托业内领先、自主可控的GIS平台系列软件，打造空天地智能一体化数字孪生大数据平台，面向各领域提供GIS行业产品和解决方案。',
        iconurl: 'assets/yskimg/home/hyrjdz.png',
        activeiconurl: 'assets/yskimg/home/hyrjdz-active.png',
        newiconurl:
          'https://pic4.zhimg.com/v2-c267bff87dc69b67436546d2c4b4ccdb_r.jpg',
      },
      {
        id: 3,
        title: '调查监测',
        content:
          '公司具有专业的调查监测队伍、车辆、设备设施，拥有丰富的林业调查、土壤调查、耕地调查、国土调查、自然灾害风险普查等行业调查监测经验。',
        iconurl: 'assets/yskimg/home/dcjc.png',
        activeiconurl: 'assets/yskimg/home/dcjc-active.png',
        newiconurl:
          'https://pic4.zhimg.com/v2-c267bff87dc69b67436546d2c4b4ccdb_r.jpg',
      },
      {
        id: 4,
        title: '物联网监测监控',
        content:
          '公司具有智能电表、智能水表、水文仪器、水利自动化仪器等物联网监测监控集成实施能力，自主研发了水雨情监测、大坝安全监测、污染源在线监测、排水管网监测、水电暖智能控制及计收、防汛预警预报、闸门与泵站调度监控、水环境监测与水资源实时监控等物联网监测监控系统。',
        iconurl: 'assets/yskimg/home/wlwjcjk.png',
        activeiconurl: 'assets/yskimg/home/wlwjcjk-active.png',
        newiconurl:
          'https://pic4.zhimg.com/v2-c267bff87dc69b67436546d2c4b4ccdb_r.jpg',
      },
    ],
  },
  home_content2: {
    titleEng1: 'SOLUTION',
    title: '行业解决方案',
    titlemsg: '',
    main: [
      {
        id: 1,
        iconurl: 'assets/yskimg/home/智慧水务.png',
        title: '智慧水务',
      },
      {
        id: 2,
        iconurl: 'assets/yskimg/home/基础测绘.png',
        title: '基础测绘',
      },
      {
        id: 3,
        iconurl: 'assets/yskimg/home/档案管理.png',
        title: '档案管理',
      },
      {
        id: 4,
        iconurl: 'assets/yskimg/home/智慧水利.png',
        title: '智慧水利',
      },
      // {
      //   iconurl: 'assets/yskimg/home/banner.png',
      //   title: '智慧水务',
      // },
      // {
      //   iconurl: 'assets/yskimg/home/banner.png',
      //   title: '基础测绘',
      // },
    ],
  },
  home_content3: {
    titleEng1: 'NEWS',
    title: '新闻动态',
    titlemsg: '',
    main1: [
      { url: 'assets/yskimg/home/新闻.png' },
      { url: 'assets/yskimg/home/banner-jcch.jpg' },
      { url: 'assets/yskimg/home/banner-zhps.jpg' },
      { url: 'assets/yskimg/home/banner-zrzy.png' },
    ],
    main2: [
      {
        title: '南方区Q1管理会满满干货|三条产品线并行…',
        content:
          '南京元时空Q2季度总裁例会在南京顺利召开，总结2022年全新的工作变化与成长，延展思路，明确规划......',
        time: '2018.08.26',
      },
      {
        title: '南方区Q1管理会满满干货|三条产品线并行…',
        content:
          '南京元时空Q2季度总裁例会在南京顺利召开，总结2022年全新的工作变化与成长，延展思路，明确规划......',
        time: '2018.08.26',
      },
    ],
    main3: [
      {
        title: '南方区Q1管理会满满干货|三条产品线并行…',
        content:
          '南京元时空Q2季度总裁例会在南京顺利召开，总结2022年全新的工作变化与成长，延展思路，明确规划......',
        time: '2018.08.26',
      },
      {
        title: '南方区Q1管理会满满干货|三条产品线并行…',
        content:
          '南京元时空Q2季度总裁例会在南京顺利召开，总结2022年全新的工作变化与成长，延展思路，明确规划......',
        time: '2018.08.26',
      },
      {
        title: '南方区Q1管理会满满干货|三条产品线并行…',
        content:
          '南京元时空Q2季度总裁例会在南京顺利召开，总结2022年全新的工作变化与成长，延展思路，明确规划......',
        time: '2018.08.26',
      },
    ],
  },
};
