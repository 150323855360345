<template>
  <div class="tab-three">
    <div class="left-content">
      <div class="main-content">
        <div class="inner-title">
          <div class="title">
            <div>调查监测</div>
          </div>
          <div class="subtitle"></div>
        </div>
        <div class="introduct-bg"></div>
        <p class="introduct">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司具有专业的调查监测队伍、车辆、设备设施，拥有丰富的林业调查、土壤调查、耕地调查、国土调查、自然灾害风险普查等行业调查监测经验。
        </p>
        <div class="bottom-title"></div>
      </div>
    </div>
    <div class="pic"></div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/business.json';
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-three {
  padding-top: 60px;
  display: flex;
  width: 90%;
  margin: 0 auto;
  .pic {
    width: 341px;
    height: 454px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.6s ease-out 0s;
    background: url('../../assets/yskimg/business/dcjk.png') no-repeat center;

    background-size: 100% 100%;
  }

  .left-content {
    flex: 1;
    position: relative;

    .main-content {
      width: 100%;
      background: #ffffff;
      height: 454px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
    }
  }

  .inner-title {
    // padding-left: 20px;
    position: relative;

    .title {
      font-size: 26px;
      color: #208ee1;
      line-height: 48px;
    }

    .subtitle {
      position: absolute;
      height: 0px;
      width: 100px;
      border: 1px solid #208ee1;
    }
  }

  .introduct {
    font-size: 18px;
    color: #000000;
    line-height: 48px;
    padding: 20px 20px 20px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    .single {
      width: 21px;
      height: 18px;
      font-size: 60px;
      font-weight: 400;
      color: #208ee1;
      line-height: 30px;
    }
  }
  .introduct-bg {
    width: 50%;
    height: 380px;
    position: absolute;
    right: 0;
    background: rgba(245, 245, 245, 0.5);
    z-index: -1;
  }
  .bottom-title {
    position: absolute;
    bottom: 0;
    width: 48px;
    height: 18px;
    background: #208ee1;
  }
}
</style>
