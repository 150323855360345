<template>
  <div class="tab-five">
    <div class="enterus-panel">
      <EnterusTitleLayout
        title="JOIN US"
        subtitle="加入我们"
      ></EnterusTitleLayout>
      <div class="body">
        <div class="top-welfare">
          <div class="item" v-for="(item, index) in welfaredata" :key="index">
            <div
              class="pic"
              :style="
                'background-image: url(' + require('@/' + item.imgurl) + ')'
              "
            ></div>
            <div class="name fz25">{{ item.name }}</div>
          </div>
        </div>
        <div class="bottom-recruit">
          <div class="recruit-block">
            <div class="title fz26">项目经理</div>
            <div class="content">
              岗位职责：<br />
              1、负责自然资源行业信息化软件项目需求分析，合理把控项目需求边界，高效组织项目设计和应用解决方案的制定；<br />
              2、带领项目团队积极推进项目实施，把控项目整体进度与质量，确保按时交付，具备管控需求变更等项目风险的能力;解决或协调团队在实施过程中所遇见的各类问题；<br />
              3、带领项目团队认真执行公司制定的项目管理规范，配合部门做好项目团队的建设和培养。<br />
              任职要求：<br />
              1、全日制本科及以上学历，计算机、GIS或相关专业;<br />
              2、能够组织制定项目的总体计划和阶段计划，有较强的需求分析、软件设计经验和汇报文档编写能力；<br />
              3、熟练使用
              ArcGIS、AutoCAD等软件，熟悉Oracle或PostgreSQL或MySQL一种，熟练使用SQL语言；<br />
              4、有相关GIS行业公司、工作经历者优先；有自然资源、生态环境、水利水务、智慧城市、时空云平台等项目开发经验优先；<br />
              5、具有良好的表达和沟通能力富有团队精神、强烈的责任感；<br />
              6、性格开朗，善于沟通，能与团队成员友好相处；<br />
              7、具有良好的职业道德和工作态度，工作认真、踏实，具有强烈的事业心和进取心，善于执行任务、解决问题。<br />
              <span style="display: block; margin-top: 20px">
                有意者请将详细简历发送到：njyskdlxx@sina.com
              </span>
            </div>
          </div>
          <div class="recruit-block">
            <div class="title fz26">Web前端开发工程师</div>
            <div class="content">
              岗位职责：<br />
              1、根据公司项目安排，参与项目需求调研与分析，参与系统前端设计;<br />
              2、能够按照公司制定的项目开发规范，承担WEB前端模块的开发工作，注重前端页面的美观、性能及交互体验，与后端开发工程师紧密沟通、协作，并对自己的开发质量及进度负责;<br />
              3、对公司的产品提出用户界面和用户体验的合理建议，致力于产品提升。<br />
              任职要求：<br />
              1、全日制本科及以上学历，计算机、GIS或相关专业；<br />
              2、熟悉WebGIS相关原理及arcgis js api或open street
              map或mapbox或leaflet等主流地图框架者优先;<br />
              3、熟悉Dojo/Jquery/bootstrap/vue等前端框架，熟练使用webpack等前端自动化打包工具;熟悉Git命令，使用Git相关代码管理工具;<br />
              4、有相关GIS行业公司、工作经历者优先；有自然资源、生态环境、水利水务、智慧城市、时空云平台等项目开发经验优先；<br />
              5、具有良好的表达和沟通能力富有团队精神、强烈的责任感；<br />
              6、性格开朗，善于沟通，能与团队成员友好相处；<br />
              7、具有良好的职业道德和工作态度，工作认真、踏实，具有强烈的事业心和进取心，善于执行任务、解决问题。<br />
              <span style="display: block; margin-top: 20px">
                有意者请将详细简历发送到：njyskdlxx@sina.com
              </span>
            </div>
          </div>
          <div class="recruit-block">
            <div class="title fz26">JAVA开发工程师</div>
            <div class="content">
              岗位职责：<br />
              1、 负责支持开发经理、项目经理的工作，支撑项目开发交付;<br />
              2、能够按照公司制定的项目开发规范，与项目团队协作承担软件平台系统的后端服务、接口开发工作，并与前端开发工程师紧密沟通、协作，对自己的开发质量及进度负责;
              <br />
              3、参与架构设计及技术评审;<br />
              4、具备—定独立带领团队能力。<br />
              任职要求：<br />
              1、全日制本科及以上学历，计算机、GIS或相关专业；<br />
              2、具有扎实的Java基础，理解IO、多线程，常用数据结构、算法、设计模式；<br />
              3、熟悉主流开源框架技术,Spring MVC,
              SpringBoot,SpirngCloud,MyBatis，Maven、Git;<br />
              4、熟悉Linux操作系统，掌握常用的Linux命令;<br />
              5、熟悉REST规范，熟悉HTTP, WebSocket等常用网络协议;<br />
              6、
              有相关GIS行业公司、工作经历者优先；有自然资源、生态环境、水利水务、智慧城市、时空云平台等项目开发经验优先。<br />
              <span style="display: block; margin-top: 20px">
                有意者请将详细简历发送到：njyskdlxx@sina.com
              </span>
            </div>
          </div>
          <div class="recruit-block">
            <div class="title fz26">GIS数据处理工程师</div>
            <div class="content">
              岗位职责：<br />
              1、负责测绘内业数据处理，使用CAD、ArcGIS、SuperMap、CityEngine等数据处理软件完成数据采集、建库、制图工作;<br />
              2、保质保量的完成内业经理分配的生产任务;<br />
              3、完成上级领导交代的其它工作。<br />
              任职要求：<br />
              1、大专及以上学历，地理信息系统、测绘工程、摄影测量与遥感、土地资源管理等测绘相关专业;<br />
              熟练使用CAD、ArcGIS、SuperMap、CityEngine等软件，熟练应用处理测绘地理信息数据，具备遥感、测绘专业基础或有地图制图经验、熟悉采集、建库、制图生产流程;
              <br />
              2、有相关GIS行业公司、工作经历者优先；有自然资源、生态环境、水利水务、智慧城市、时空云平台等项目开发经验优先；<br />
              3、具有良好的表达和沟通能力富有团队精神、强烈的责任感；<br />
              4、性格开朗，善于沟通，能与团队成员友好相处；<br />
              5、具有良好的职业道德和工作态度，工作认真、踏实，具有强烈的事业心和进取心，善于执行任务、解决问题。<br />
              <span style="display: block; margin-top: 20px">
                有意者请将详细简历发送到：njyskdlxx@sina.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnterusTitleLayout from '@/components/layout/EnterusTitleLayout';
export default {
  components: {
    EnterusTitleLayout,
  },
  data() {
    return {
      welfaredata: [
        { name: '五险一金', imgurl: 'assets/yskimg/enterus/welfare1.png' },
        { name: '定期团建', imgurl: 'assets/yskimg/enterus/welfare2.png' },
        { name: '年终奖', imgurl: 'assets/yskimg/enterus/welfare3.png' },
        { name: '餐饮补贴', imgurl: 'assets/yskimg/enterus/welfare4.png' },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-five {
  width: 1100px;
  margin: 0 auto;
  .enterus-panel {
    padding-top: 60px;
    width: 100%;
  }
  .body {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    .top-welfare {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .item {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 300;
          color: #208ee1;
          line-height: 25px;
          margin-top: 20px;
          text-align: center;
        }
        .pic {
          width: 121px;
          height: 121px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          background: #ffffff;
          box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.6s ease-out 0s;
          background-image: url('../../assets/yskimg/home/banner.png');
        }
      }
    }
    .bottom-recruit {
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .recruit-block {
        display: flex;
        flex-direction: column;

        width: 40%;
        .title {
          margin: 30px 0;
          font-weight: 500;
          color: #208ee1;
          line-height: 25px;
        }
        .content {
          font-size: 15px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #505050;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
