<template>
  <div class="login-body">
    <div class="login-input">
      <span class="title">元时空地理信息技术有限公司</span>
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="medium"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        style="width: 356px; margin: 30px auto"
      >
        <el-col :span="24">
          <el-form-item prop="mobilePhone">
            <el-input
              v-model="dataForm.mobilePhone"
              clearable
              style="width: 100%"
              placeholder="帐号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="password">
            <el-input
              v-model="dataForm.password"
              style="width: 100%"
              type="password"
              placeholder="密码"
              show-password
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-button
          class="login-btn-submit"
          type="warning"
          style="width: 100%"
          @click="dataFormSubmit()"
        >
          登录
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request.js';
export default {
  components: {},
  data() {
    return {
      dataForm: {
        mobilePhone: 'admin',
        password: 'abcd@1234',
      },
      dataRule: {
        mobilePhone: [
          { required: true, message: '帐号不能为空', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.login();
        }
      });
    },
    login() {
      let params = {
        username: this.dataForm.mobilePhone,
        password: this.dataForm.password,
      };
      post('loginnoverification', params)
        .then((res) => {
          sessionStorage.setItem('token', res.token);
          sessionStorage.setItem('userInfo', JSON.stringify(res.userInfo));
          this.$router.push('/newsmanagement');
        })
        .catch((e) => {});
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.login-body {
  height: 100vh;
  position: relative;
  background: rgba(43, 150, 229, 0.8);
  .login-input {
    // height: 55%;
    position: absolute;
    width: 420px;
    // float: right;
    right: 225px;
    top: calc(100vh - 75%);
    border: 1px solid #ddd;
    border-radius: 8px;
    background: white;
    text-align: center;
    .el-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .el-col {
        margin-bottom: 5px;
      }
    }

    .title {
      color: #1069fe;
      font-size: 25px;
      font-weight: bold;
      margin-top: 2vh;
      padding-top: 30px;
      display: block;
    }
  }
}
</style>
