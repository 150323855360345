<template>
  <div class="main-body" ref="mainbody">
    <!-- 轮播图 -->
    <Banner @handleNextPanel="handleNextPanel"></Banner>
    <MainOne :paneloneShow="paneloneShow"></MainOne>
    <MainTwo :paneltwoShow="paneltwoShow"></MainTwo>
    <MainThree :panelthreeShow="panelthreeShow"></MainThree>
  </div>
</template>

<script>
// import '@/assets/js/index.js'

import Banner from './components/Banner.vue';
import MainThree from './components/MainThree.vue';
import MainTwo from './components/MainTwo.vue';
import MainOne from './components/MainOne.vue';
export default {
  components: {
    Banner,
    MainThree,
    MainOne,
    MainTwo,
  },
  data() {
    return {
      currentScroll: 0,
      paneloneShow: false,
      paneltwoShow: false,
      panelthreeShow: false,
    };
  },
  created() {},
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    window.addEventListener('scroll', this.handleScrollbox, true);
  },
  methods: {
    handleNextPanel(isnextpanel) {
      if (isnextpanel) {
        let scrollItem = document.getElementById('panelone');
        window.scrollTo({
          top: scrollItem.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    },
    handleScrollbox() {
      let scrollItem = document.getElementById('panelone');
      let scrollItem2 = document.getElementById('paneltwo');
      let scrollItem3 = document.getElementById('panelthree');
      // if (this.paneloneShow && this.paneltwoShow && this.panelthreeShow) {
      //   window.removeEventListener('scroll', this.handleScrollbox, true);
      // }
      if (window.pageYOffset - this.currentScroll > 0) {
        if (this.currentScroll >= scrollItem.offsetTop - 600) {
          this.paneloneShow = true;
        }

        if (this.currentScroll >= scrollItem2.offsetTop - 300) {
          this.paneltwoShow = true;
        }

        if (this.currentScroll >= scrollItem3.offsetTop - 600) {
          this.panelthreeShow = true;
        }
      } else {
        // if (this.currentScroll <= scrollItem.offsetTop - 300) {
        //   this.paneloneShow = false;
        // }
        // if (this.currentScroll <= scrollItem2.offsetTop - 300) {
        //   this.paneltwoShow = false;
        // }
        // if (this.currentScroll <= scrollItem3.offsetTop - 300) {
        //   this.panelthreeShow = false;
        // }
      }
      this.currentScroll = window.pageYOffset;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollbox, true);
  },
};
</script>
<style lang="less" scoped>
.main-body {
  padding-top: 100px;
  // height: 100%;
  // overflow: hidden;
  // overflow-y: scroll;
}
</style>
