<template>
  <div class="tab-one">
    <p class="introduce fz18">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ introduce }}
    </p>
    <div class="inner-title">
      <div class="title fz30">设备情况</div>
      <div class="subtitle fz20">Equipment condition</div>
    </div>
    <p class="sb-introduce fz18">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ sbintroduce }}
    </p>
    <div class="sb-body">
      <div
        class="sb-item"
        v-for="(item, index) in datalist"
        :key="index"
        @mouseenter="handlemouseenter(item)"
        @mouseleave="handlemouseleave"
      >
        <div
          class="pic"
          :style="'background-image: url(' + require('@/' + item.picurl) + ')'"
        ></div>
        <div class="detail">
          <div class="title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="inner-title">
      <div class="title fz30">作业软件情况</div>
      <div class="subtitle fz20">Operating software condition</div>
    </div>
    <div class="zy-body">
      <div class="zy-row">
        <span v-for="(item, index) in rjdata1" :key="index">{{
          item.name
        }}</span>
      </div>
      <div class="zy-row" style="margin-top: 20px">
        <span v-for="(item, index) in rjdata2" :key="index">{{
          item.name
        }}</span>
      </div>
      <div class="zy-row" style="margin-top: 20px">
        <span
          v-for="(item, index) in rjdata3"
          :key="index"
          :style="item.name ? '' : 'background-color:transparent'"
          >{{ item.name }}</span
        >
      </div>
      <!-- <div class="zy-row" style="margin-top: 20px">
        <span v-for="(item, index) in rjdata4" :key="index">{{
          item.name
        }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Resultjson from '../../../public/json/business.json';
export default {
  components: {},
  data() {
    return {
      introduce:
        '公司以政府部门及各类企事业单位为服务对象，为城市规划、自然资源管理、生态与环境管理、水利、水务管理等提供测绘技术、咨询服务、地理信息建库及信息化系统建设服务。业务范围包括：不动产测绘（房产测量）、管线探测与检测、地形测绘、地籍测量、控制测量、航空摄影测量、遥感影像处理、GIS 数据加工、地图编制、测绘监理、地理信息数据建库、GIS数据库管理系统、专业GIS系统开发等。',
      activeMainLiID: 1,
      sbintroduce:
        '配备有GPS卫星定位仪、全站仪、数字摄影测量工作站、数字绘图仪等先进的仪器设备。',
      datalist: Resultjson.data.datalist,
      rjdata1: Resultjson.data.rjdata1,
      rjdata2: Resultjson.data.rjdata2,
      rjdata3: Resultjson.data.rjdata3,
      rjdata4: Resultjson.data.rjdata4,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handlemouseenter() {},
    handlemouseleave() {},
  },
};
</script>
<style lang="less" scoped>
.tab-one {
  p.introduce {
    line-height: 48px;
    margin: 60px 0;
  }

  .inner-title {
    border-bottom: 1px solid #208ee1;

    .title {
      color: #208ee1;
    }

    .subtitle {
      color: #323232;
      padding: 10px 0;
      opacity: 0.3;
    }
  }

  p.sb-introduce {
    line-height: 48px;
    margin: 30px 0;
  }

  .sb-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .sb-item {
      padding: 10px;
      cursor: pointer;

      .pic {
        width: 484px;
        height: 290px;
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.08);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.6s ease-out 0s;
        // background-image: url('../../assets/yskimg/home/banner.png');
      }

      .detail {
        background-color: #fff;
        width: 484px;
        padding: 20px 0 40px 0;

        .title {
          position: relative;
          font-size: 18px;
          margin: 20px 0 0 0;
          text-align: center;
        }
      }
    }
  }

  .zy-body {
    padding-top: 60px;

    .zy-row {
      display: flex;
      justify-content: space-between;

      span {
        width: 160px;
        text-align: center;
        font-size: 18px;
        padding: 5px 0px;
        color: #000;
        border-radius: 4px;
        cursor: pointer;
        background-color: #f0f7fd;
      }
    }
  }
}
</style>
