<template>
  <div class="title-component-layout">
    <div class="title_topmain">
      <div class="title_left fz30">
        {{ title }}
      </div>
      <div class="title_center fz25" v-if="subtitle">/</div>
      <div class="title_right fz25">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'describe'],
  name: 'SolutionTitleLayout',
};
</script>
<style lang="less" scoped>
.title-component-layout {
  .title_topmain {
    display: flex;
    justify-content: center;
    align-items: center;

    .title_left {
      color: #333333;
    }

    .title_center {
      color: #b1b1b1;
    }

    .title_right {
      color: #b1b1b1;
    }
  }
}
</style>
