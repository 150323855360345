<template>
    <div class="main-body">
        <div>
            <editor v-model="noticeContent" :min-height="192" />
        </div>
        <el-button class="btn" type="danger" size="medium" @click="releaseBtn">打印</el-button>
        <div>{{ noticeContent }}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            noticeContent: ''
        };
    },
    created() {

    },
    mounted() { },
    methods: {
        releaseBtn(){
            console.log(this.noticeContent);
        }
    },
};
</script>
<style scoped lang="less">
.main-body {
    padding-top: 100px;
    padding: 100px;

    text-align: left;
    .btn{
        margin-top: 10px;
    }

}
</style>